export enum Status {
    UNKNOWN,
    OPEN,
    RESTRICTIONS,
    CLOSED,
}

export interface Country {
    "countryCode": string;
    "countryName": string;
    "borderStatus": Status;
    "unvaccinatedBorderStatus": Status;
    "arrivalTestRequired": boolean;
    "arrivalQuarantineRequired": boolean;
    "statusOverviews": {
        "status": Status,
        "overview": string
    }[];
    "borderDetails": string[];
    "calculatedStatus"?: Status;
}

export const data: Country[] = [
    {
        "countryCode": "AF",
        "countryName": "Afghanistan",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Afghanistan."
            }
        ],
        "borderDetails": [
            "Afghanistan's border is open to travel and international flights are slowly restarting. However, the security situation in the country remains extremely volatile. Please always check the latest news before considering travel to Afghanistan. \r\n\r\n",
            " Travelers may need a pre-approval letter from the local authority in Afghanistan before traveling. "
        ]
    },
    {
        "countryCode": "AL",
        "countryName": "Albania",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Albania with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Albania without restrictions."
            }
        ],
        "borderDetails": [
            "Due to the newly found SARS-CoV-2 virus variant named Omicron, foreign nationals who have a travel history within the last 14 days in South Africa, Kenya, Angola, Zambia, Botswana or Senegal are banned from entering Albania. \r\n\r\n",
            "All travelers arriving from countries other than the aforementioned countries, including transit passengers, must present any of the following:",
            "1. Vaccination certificate, where at least two weeks have passed from the date of full vaccination;",
            "2. Negative PCR test taken within 72 hours or a rapid antigen test taken within 48 hours or;",
            "3. Proof of recovery on Sars-Cov2 infection, which is valid for up to 6 months after healing.",
            "More details can be found <a target=\"_blank\" href=\"http://punetejashtme.gov.al/al/informacion-mbi-levizjen-e-shtetasve-te-huaj-ne-shqiperi\">here.</a>"
        ]
    },
    {
        "countryCode": "DZ",
        "countryName": "Algeria",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Algeria."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "AS",
        "countryName": "American Samoa",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": false,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter American Samoa."
            }
        ],
        "borderDetails": [
            "Travelers are not allowed to enter American Samoa. Regularly scheduled passenger flights and entry permits are suspended until further notice."
        ]
    },
    {
        "countryCode": "AO",
        "countryName": "Angola",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Angola."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "AI",
        "countryName": "Anguilla",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Anguilla."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Anguilla without restrictions."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter Anguilla. All travelers to Anguilla including returning residents are required to obtain pre-travel authorization. Temperature screening is required for all travelers on arrival."
        ]
    },
    {
        "countryCode": "AG",
        "countryName": "Antigua And Barbuda",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Antigua And Barbuda."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Antigua And Barbuda with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "These restrictions do not apply to fully vaccinated travelers and travelers under the age of 18. Temperature screening is required for all travelers on arrival."
        ]
    },
    {
        "countryCode": "AR",
        "countryName": "Argentina",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Argentina."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Argentina with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Vaccinated individuals are allowed to visit Argentina for tourism purposes. They may enter thru \r\n<a target=\"_blank\" href=\"https://www.argentina.gob.ar/interior/migraciones/ddjj-migraciones\">Argentina's safe corridors</a>. ",
            "These travelers are required to present a \r\ncertificate of vaccination, a negative result of RT-PCR test taken 72 hours prior to boarding, and an insurance with COVID-19 coverage."
        ]
    },
    {
        "countryCode": "AM",
        "countryName": "Armenia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Armenia with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Armenia without restrictions."
            }
        ],
        "borderDetails": [
            "Most travelers can enter Armenia, but there are restrictions."
        ]
    },
    {
        "countryCode": "AW",
        "countryName": "Aruba",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Aruba."
            }
        ],
        "borderDetails": [
            "Most travelers are allowed to enter Aruba."
        ]
    },
    {
        "countryCode": "AU",
        "countryName": "Australia",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Australia."
            }
        ],
        "borderDetails": [
            "You are automatically exempt from the travel restrictions and can enter Australia (without obtaining an individual exemption) if you are:",
            "\n\n- an Australian citizen",
            "\n- a permanent resident of Australia",
            "\n- an immediate family member of an Australian citizen or permanent resident ",
            "\n- a New Zealand citizen usually resident in Australia and their immediate family members",
            "\n- a person who has been in New Zealand for 14 days or more immediately prior to arrival by air in Australia",
            "\nAll inbound passengers must provide proof of vaccination unless they are a child aged 12 years and 3 months, or younger.",
            "\nTo consult all the exceptions please check <a target=\"_blank\" href=\"https://covid19.homeaffairs.gov.au/travel-restrictions\">here</a>.\n",
            "\nTravelers planning to travel across Australian state borders should note that some Australian states also have domestic travel restrictions, border restrictions, and quarantine requirements in place. In the case of onward travel within Australia, travelers are advised to check in advance with their travel/accommodation provider. ",
            "\nFully vaccinated Australians and permanent residents can now travel into Australia."
        ]
    },
    {
        "countryCode": "AT",
        "countryName": "Austria",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Austria."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Austria with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Entry restrictions do not apply to Citizens and residents of Austria, EU citizens, travelers under the age of 12, travelers with proof of being fully vaccinated, and travelers with proof of recovery that meets Austrian government standards.\n\n",
            "Arriving travelers from South Africa, Angola, Botswana, Eswatini, Lesotho, Malawi, Mozambique, Namibia, Zambia, or Zimbabwe are not allowed to enter Austria. Citizens of Austria are exempted from this entry restriction."
        ]
    },
    {
        "countryCode": "AZ",
        "countryName": "Azerbaijan",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Azerbaijan."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Azerbaijan with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Travelers from the United States may be allowed to enter Azerbaijan under the following conditions: \r\n",
            "1.Submission of an official document issued by the relevant country (COVID passport) on complete vaccination or recovery from COVID-19 for passengers over 18 years of age, and a negative PCR test taken within 72 hours before the flight. ",
            "2.Submission of a negative PCR test taken within 72 hours before the flight for passengers aged from 1 to 18 years.\r\n"
        ]
    },
    {
        "countryCode": "BH",
        "countryName": "Bahrain",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Bahrain."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Bahrain with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter the country."
        ]
    },
    {
        "countryCode": "BD",
        "countryName": "Bangladesh",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Bangladesh."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Bangladesh with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Most travelers can enter Bangladesh, but there are restrictions. Temperature screening is required for all travelers on arrival."
        ]
    },
    {
        "countryCode": "BB",
        "countryName": "Barbados",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Barbados."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Barbados with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "The online immigration and customs form should be completed 24 hours prior to arrival, and preferably include the upload of your negative Nasopharyngeal or Oropharyngeal COVID-PCR test result."
        ]
    },
    {
        "countryCode": "BY",
        "countryName": "Belarus",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Belarus with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Belarus without restrictions."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "BE",
        "countryName": "Belgium",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Belgium."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Belgium without restrictions."
            }
        ],
        "borderDetails": [
            "Non-essential travel to Belgium is prohibited for people who are not a national of the EU, nor of a country that is part of the Schengen Area, except for fully vaccinated individuals with a recognized vaccination certificate from 1 July 2021.\r\n\r\n",
            "\r\nTravelers under the age of 18 who arrive in Belgium from a country outside the European Union or Schengen Area will now be permitted entry regardless of their vaccination status, but they should be accompanied by another person who holds a valid vaccination certificate.\r\n",
            " All incoming travelers to Belgium should fill in the <a target=\"_blank\" href=\"https://travel.info-coronavirus.be/public-health-passenger-locator-form \">Passenger Locator Form</a> 48 hours before your travel. Depending on the country or region you are traveling from, different measures apply after your arrival in Belgium."
        ]
    },
    {
        "countryCode": "BZ",
        "countryName": "Belize",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Belize."
            }
        ],
        "borderDetails": [
            "Most travelers can enter Belize, but there are restrictions."
        ]
    },
    {
        "countryCode": "BJ",
        "countryName": "Benin",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Benin."
            }
        ],
        "borderDetails": [
            "Travelers must register at the <a target=\"_blank\" href=\"https://centresurveillancesanitaire.bj/\">appointment website</a> to schedule a test and pay the testing fee."
        ]
    },
    {
        "countryCode": "BM",
        "countryName": "Bermuda",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Bermuda."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Bermuda."
            }
        ],
        "borderDetails": [
            "All visitors arriving by air or sea to Bermuda must be fully vaccinated. This does not apply to persons younger than 12 years and crew. All immunized travelers will be given a red wristband upon arrival. Immunized travelers with pretest must wear it until receiving the negative arrival test. Immunized travelers WITHOUT pretest must wear it until receiving the negative DAY 4 test."
        ]
    },
    {
        "countryCode": "BT",
        "countryName": "Bhutan",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Bhutan."
            }
        ],
        "borderDetails": [
            "Travelers are not allowed to enter Bhutan. Visa on arrival services are suspended until further notice. These travel restrictions do not apply to citizens of Bhutan."
        ]
    },
    {
        "countryCode": "BO",
        "countryName": "Bolivia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Bolivia."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Bolivia with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "All non-resident foreign travelers arriving in Bolivia are required to prove that they have an insurance with COVID-19 coverage that will be used for their treatment in case they will get a positive result of COVID-19."
        ]
    },
    {
        "countryCode": "BA",
        "countryName": "Bosnia and Herzegovina",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Bosnia and Herzegovina with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Bosnia and Herzegovina without restrictions."
            }
        ],
        "borderDetails": [
            "Travelers are permitted to enter Bosnia and Herzegovina with one of the following documents :\n\n",
            "1. A negative result of PCR test or rapid antigen test for SARS-CoV-2 virus not older than 48 hours if coming from European countries, and not older than 72 hours if coming other countries",
            "2. Certificate of vaccination",
            "3. Certificate issued by a doctor that the traveler has overcome the disease COVID-19 in the past period from 14 days to 180 days from the date of arrival",
            "\n\nFull details can be found on the <a target=\"_blank\" href=\"http://www.granpol.gov.ba/Content/Read/76?title=Stranci \">Bosnia and Herzegovina Border Police</a> website."
        ]
    },
    {
        "countryCode": "BW",
        "countryName": "Botswana",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Botswana."
            }
        ],
        "borderDetails": [
            "Most international travelers are allowed to enter Botswana. Travelers must pass a temperature check on arrival."
        ]
    },
    {
        "countryCode": "BR",
        "countryName": "Brazil",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Brazil."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Brazil with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Effective December 18, 2021 - Travelers are required to present proof of vaccination with any of the WHO-approved vaccines. The presentation of proof of vaccination will be waived for travelers :\n",
            "1. with a health condition that contraindicates vaccination, provided that it is certified by a medical report",
            "2. not eligible for vaccination due to age",
            "3. due to humanitarian issues",
            "4. coming from countries with low vaccination coverage published by the Ministry of Health ",
            "5. Brazilians and foreigners residing in Brazilian territory who are not fully vaccinated ",
            "Travelers exempt from proof of vaccination, upon entering Brazilian territory, must undergo quarantine for 14 days in the city of their final destination and at the address registered in the <a target=\"_blank\" href=\"https://formulario.anvisa.gov.br/ \">Traveler's Health Declaration</a>. See full details of the updated entry restrictions in the <a target=\"_blank\" href=\"https://www.in.gov.br/en/web/dou/-/portaria-interministerial-n-663-de-20-de-dezembro-de-2021-368622644 \">Interministerial Ordinance No. 663</a>."
        ]
    },
    {
        "countryCode": "VG",
        "countryName": "British Virgin Islands",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter the British Virgin Islands with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter the British Virgin Islands without restrictions."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter the British Virgin Islands."
        ]
    },
    {
        "countryCode": "BN",
        "countryName": "Brunei Darussalam",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Brunei Darussalam."
            }
        ],
        "borderDetails": [
            "Traveling to Brunei is allowed for Brunei Citizens, Permanent Residents, and travelers with compelling reasons.\r\n\r\n",
            "Travelers with compelling reasons are required to obtain an <a href=\"https://www.form.gov.bn/ETPA/Pages/ETP-Registration-Form.aspx\" target=\"_blank\">Entry Travel Pass approval</a>."
        ]
    },
    {
        "countryCode": "BG",
        "countryName": "Bulgaria",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Bulgaria."
            }
        ],
        "borderDetails": [
            "Passengers arriving from Mozambique, Botswana, South Africa, Lesotho, Eswatini, Namibia and Zimbabwe are banned from entering Bulgaria. This restriction does not apply to Bulgarian citizens and persons with the status of permanent, long-term or continuous residence in the country and their family members.\r\n\r\n",
            "Restrictions in going to Bulgaria depend on the <a target=\"_blank\" href=\"https://www.mfa.bg/en/customnews/main/24737\">category of the country</a> where the travelers are arriving from.",
            "Travelers arriving from GREEN AND ORANGE COUNTRIES are required to present either a valid EU digital COVID-19 vaccination certificate; a valid EU digital COVID-19 recovery certificate; a valid EU digital COVID-19 test (a document showing a negative result of RT-PCR test taken 72 hours or a negative result from a rapid antigen test conducted up to 48 hours before entry into the country); or a similar document containing the same data as the EU digital COVID-19 certificate (vaccination/recovery/testing).",
            " Travelers coming from the \"RED COUNTRIES\" are allowed to enter Bulgaria provided that they are Bulgarian citizens, long term residents or permanent residents of Bulgaria, EU/EEA and Swiss Confederation citizens (including their family members), citizens of the United Kingdom of Great Britain and the Northern Ireland ( including their family members) and if travelers have a compelling reason."
        ]
    },
    {
        "countryCode": "BF",
        "countryName": "Burkina Faso",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Burkina Faso."
            }
        ],
        "borderDetails": [
            "All land borders remain closed."
        ]
    },
    {
        "countryCode": "BI",
        "countryName": "Burundi",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Burundi."
            }
        ],
        "borderDetails": [
            "Please check the official guidelines from Burundi before traveling."
        ]
    },
    {
        "countryCode": "KH",
        "countryName": "Cambodia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Cambodia."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Cambodia without restrictions."
            }
        ],
        "borderDetails": [
            "Entry restrictions do not apply to:",
            "<ul>\n\n<li>Diplomats and their families \n<li>Colombian nationals and residents \n<li>Travelers aged 17 and younger</ul>",
            "More information on entry to Cambodia can be found <a target=\"_blank\" href=\"https://www.mfaic.gov.kh/covid-19\">here</a>."
        ]
    },
    {
        "countryCode": "CM",
        "countryName": "Cameroon",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Cameroon."
            }
        ],
        "borderDetails": [
            "Most travelers are not allowed to enter Cameroon. Commercial flights to and from Cameroon are limited. This entry restrictions do not apply to Cameroon citizens, residents, and those with valid visas."
        ]
    },
    {
        "countryCode": "CA",
        "countryName": "Canada",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Canada."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Canada with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "The travel restrictions do not apply to Canadian citizens, Permanent residents, Extended family members include those in a long-term, exclusive dating relationship and their dependent children, plus adult children, grandchildren, siblings, and grandparents under certain conditions, Foreign nationals for compassionate reasons in specific and limited situations, including to say goodbye to a loved one, to provide care for a loved one, or to attend a funeral.",
            " Non-essential travel such as Tourism, Recreation, Entertainment is not allowed.",
            " If you received your vaccines outside Canada, it’s still accepted but proof of vaccination must be uploaded digitally in ArriveCAN and must only be in French or English, or certified translation into French or English. Accepted COVID-19 vaccines in Canada are\nPfizer BioNTech,Moderna,AstraZeneca/COVISHIELD and\nJanssen/Johnson and Johnson.",
            "Vaccination is required for travel within and out of Canada. traveler must have completed the full course of vaccination with a Government of Canada-accepted vaccine at least 14 days before arrival in Canada are allowed to enter Canada. A valid COVID-19 molecular test is no longer accepted as an alternative to vaccination. Fully vaccinated Canadian citizens, permanent residents, or individuals registered under the Indian Act who depart and re-enter Canada by land or by air within 72 hours of leaving the country do not have to provide a pre-entry molecular test result. ",
            " Travelers who have been in any of these 10 countries( Botswana, Egypt, Eswatini, Lesotho, Malawi, Mozambique, Namibia, Nigeria, South Africa, Zimbabwe\n) in the previous 14 days, will be subject to enhanced pre-entry and arrival testing, screening, and quarantine measures."
        ]
    },
    {
        "countryCode": "CV",
        "countryName": "Cape Verde",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Cape Verde."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter Cape Verde by air or by sea. Temperature screening is required for all travelers on arrival in Cape Verde."
        ]
    },
    {
        "countryCode": "BQ",
        "countryName": "Caribbean Netherlands",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter the Caribbean Netherlands."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "KY",
        "countryName": "Cayman Islands",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter the Cayman Islands."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter the Cayman Islands."
            }
        ],
        "borderDetails": [
            "Restriction does not apply to: \r\n",
            "Caymanians and Permanent residents",
            "Work permit holders and their families",
            "Persons who own residential property within the Cayman Island",
            "Students with a valid visa to study in the Cayman Islands",
            "Persons with close family ties to residents or work permit holders - spouses, fiancés, parents, grandparents and siblings",
            "Persons approved on the Global Citizen Concierge Program",
            "Other visitors approved via the Travel Cayman team. (For travel approval, you must apply via the <a target=\"_blank\" href=\"https://www.exploregov.ky/traveltime \">Travel Cayman Portal)</a>."
        ]
    },
    {
        "countryCode": "CF",
        "countryName": "Central African Republic",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter the Central African Republic."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "TD",
        "countryName": "Chad",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Chad."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "CL",
        "countryName": "Chile",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Chile."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Chile."
            }
        ],
        "borderDetails": [
            "Proof of a health insurance policy that provides coverage for COVID-19 and related health issues, which must cover charges associated with COVID-19 during the traveler’s stay, with a coverage of USD $ 30,000 (minimum).Except for Chilean citizens, residents, and holders of diplomatic or official passports; Citizens of Spain without residency in Chile as long as they have valid forms E-CHL/09 and E-CH/11.\n",
            " During the first 10 days after entry into the country, a tracking form will be sent to you via e-mail every day. The timely completion of this daily survey is mandatory."
        ]
    },
    {
        "countryCode": "CN",
        "countryName": "China",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter China."
            }
        ],
        "borderDetails": [
            "Entry restrictions do not apply to :\r\n",
            "- Chinese citizens",
            "- Diplomatic, service, courtesy, or C visa holders;",
            "- International travelers coming to China for necessary economic, trade, scientific or technological activities or out of emergency humanitarian needs may apply for visas at Chinese embassies or consulates."
        ]
    },
    {
        "countryCode": "CO",
        "countryName": "Colombia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Colombia."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Colombia without restrictions."
            }
        ],
        "borderDetails": [
            "Unvaccinated travelers are not allowed to enter Colombia."
        ]
    },
    {
        "countryCode": "KM",
        "countryName": "Comoros",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter the Comoros."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "CK",
        "countryName": "Cook Islands",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": false,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter the Cook Islands."
            }
        ],
        "borderDetails": [
            "The travel restrictions do not apply to Cook Islanders. Permanent Residents of the Cook Islands. Cook Islands work permit holders. And Cook Islands resident permit holders. Travelers who have been in New Zealand for 14 days before their travel are allowed to enter the Cook Islands by air under the New Zealand-Cook Islands travel bubble. Non-citizens and non-residents of New Zealand must ensure they are legally able to return to New Zealand."
        ]
    },
    {
        "countryCode": "CR",
        "countryName": "Costa Rica",
        "borderStatus": Status.OPEN,
        "unvaccinatedBorderStatus": Status.OPEN,
        "arrivalTestRequired": false,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.OPEN,
                "overview": "Most visitors from the United States, regardless of vaccination status, can enter Costa Rica."
            }
        ],
        "borderDetails": [
            "Travelers who are going to Costa Rica are not required to provide a negative PCR test result or undergo a quarantine. However, they are required to present a return flight ticket upon arrival in the country.\r\n\r\n",
            "From 1 December 2021 to 7 February 2022, commercial establishments may require travelers to present their proof of vaccination for them to enter their establishments. Travelers may present either their proof of vaccination via the QR code generated through their Health Pass or the physical card provided to them when they were vaccinated.",
            "Unvaccinated tourists, tourists with incomplete vaccination and tourists administered with vaccines other than Moderna, PfizerBioNTech, AstraZeneca, Sinovac, Sinopharm, Covaxin, or Janssen will need to present a travel insurance that covers lodging and medical expenses in case of contracting COVID-19. The required medical insurance should have a guaranteed $50,000 for medical expenses and $2000 for lodging expenses. Costa Rican citizens, tourists under the age of 18 and tourists vaccinated with approved vaccines in Costa Rica are exempt from presenting a health insurance.",
            "More details regarding entry to Costa Rica can be found <a target=\"_blank\" href=\"https://www.visitcostarica.com/en/costa-rica/planning-your-trip/entry-requirements\">here</a>."
        ]
    },
    {
        "countryCode": "HR",
        "countryName": "Croatia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Croatia with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Croatia without restrictions."
            }
        ],
        "borderDetails": [
            "Travelers may enter Croatia but will be required to meet one of the exemptions:",
            " Persons who travel for tourist reasons and have a certificate of paid accommodation in a hotel, camp, private renter or rented vessel, and another form of tourist accommodation or are owners of houses or vessels in the Republic of Croatia. ",
            " Citizens of EU/EEA member states as well as members of their families;",
            "Healthcare workers, health researchers, and caregivers for the elderly;",
            "Travelers in transit, with the obligation to leave the Republic of Croatia a maximum of 12 hours from entering;",
            "Persons traveling for necessary personal/family or business reasons, or who have other economic interests;",
            "There is a limit in the validity of your covid vaccine. If your last dose of vaccination was more than 270 days ago, You'll have to abide by the country's unvaccinated traveler rules."
        ]
    },
    {
        "countryCode": "CU",
        "countryName": "Cuba",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Cuba."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Cuba with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "These restrictions do not apply to vaccinated travelers, Cuban residents, travelers under the age of 12 years, and adults who for medical reasons, and duly certified, cannot be vaccinated with any of the vaccines approved until today by the corresponding regulatory agencies.\n",
            "Temperature screening is required for all travelers on arrival in Cuba."
        ]
    },
    {
        "countryCode": "CW",
        "countryName": "Curaçao",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Curaçao."
            }
        ],
        "borderDetails": [
            "These general requirements apply to all visitors regardless if you are traveling from a low-risk or high-risk country: ",
            "-You must not have been in contact with a person who tested positive for COVID-19 within 14 days prior to arrival. Travelers who have had recent contact with a positive case will be placed in mandatory quarantine.",
            "-You must carry a printed document of proof of the mandatory steps at all times during your travel.",
            "-You must be adequately insured for medical care and any additional costs if you are quarantined or become ill during your stay.",
            "-If you do not meet the above conditions, you may be quarantined on arrival. This is entirely at your own expense.",
            "-There are currently no exemptions for visitors who have been fully vaccinated.",
            "\n\nCuraçao imposed a travel ban for travelers from or whom during the past 14 days have been in one of the following countries, region, or places in which there is a widespread incidence of the Omicron variant of the SARS-CoV-2 virus (B.1.1.529) :\nBotswana, Eswatini (Swaziland), Lesotho, Malawi, Mozambique, Namibia, South Africa, and Zimbabwe.",
            "\n\nThe travel entry ban does not apply to the following people:",
            "\n\n-Individuals who are residents of Curaçao;",
            "\n-Aircraft crew members, who are actually working on the permitted flights to and from Curaçao;",
            "\n-Medical specialists, general practitioners, nurses or other individuals who fulfill an indispensable function in health care in Curaçao;",
            "\n-Individuals who come to perform work, who perform an indispensable function for vital processes in Curaçao;",
            "\n-Individuals from Aruba, Bonaire, St. Maarten, St. Eustatius and Saba who require acute care."
        ]
    },
    {
        "countryCode": "CY",
        "countryName": "Cyprus",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Cyprus."
            }
        ],
        "borderDetails": [
            "All travelers are required to complete the Cyprus Flight Pass 48 hours prior to departure to the country.\r\n\r\n",
            "Cyprus follows the Traffic Light System. Hence, travelers must know the <a target=\"_blank\" href=\"https://cyprusflightpass.gov.cy/en/country-categories\">category of the country</a> where they are arriving from for their entry requirements when visiting the country.",
            "\r\n\r\nFurther details in traveling to Cyprus can be found <a target=\"_blank\" href=\"https://cyprusflightpass.gov.cy/\">here</a>."
        ]
    },
    {
        "countryCode": "CZ",
        "countryName": "Czech Republic",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter the Czech Republic."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter the Czech Republic with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Fully vaccinated individuals administered with vaccines authorized by European Medicines Agency (EMA) from the countries listed <a target=\"_blank\" href=\"https://koronavirus.mzcr.cz/certifikaty/\">here</a> can travel to Czech Republic.\r\n\r\n",
            "Details in traveling to Czech Republic can be found <a target=\"_blank\" href=\"https://www.mvcr.cz/mvcren/article/coronavirus-information-of-moi.aspx\">here</a>."
        ]
    },
    {
        "countryCode": "CD",
        "countryName": "Democratic Republic of the Congo",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter the Democratic Republic of the Congo."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "DK",
        "countryName": "Denmark",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Denmark."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Denmark with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "You need a COVID Certificate or negative test to visit restaurants, cafes and many other situations.",
            "To be considered fully vaccinated, the following requirements apply:",
            "\n-The vaccine must be approved by the European Medicines Agency (EMA).\n-At least 2 weeks and no more than 12 months must have elapsed since you received your final vaccination dose.\n-Another approved vaccination process: receiving the AstraZeneca vaccine for the first jab and an mRNA vaccine (Moderna or Pfizer/BioNTech) for the second. ",
            " Accepted vaccines against COVID-19:\nPfizer BioNTech (Comirnaty),Johnsonand Johnson – Janssen,Pharmaceutical (COVID-19 Vaccine Janssen),Moderna (COVID-19 Vaccine Moderna),AstraZeneca (Vaxzevria, formerly COVID-19 Vaccine AstraZeneca",
            "\nIf you are residing in one of these countries(Angola, Botswana, Eswatini, Lesotho, Malawi, Mozambique, Namibia, South Africa, Zambia and Zimbabwe), you will also need a special worthy purpose and a negative test prior to entry."
        ]
    },
    {
        "countryCode": "DJ",
        "countryName": "Djibouti",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Djibouti."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Djibouti with a negative COVID-19 test result."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "DM",
        "countryName": "Dominica",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Dominica."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Dominica with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter Dominica. Entry requirements can be found <a target=\"_blank\" href=\"https://domcovid19.dominica.gov.dm/images/travel_protocols_new_guidance_rv13r6.pdf\">here</a>."
        ]
    },
    {
        "countryCode": "DO",
        "countryName": "Dominican Republic",
        "borderStatus": Status.OPEN,
        "unvaccinatedBorderStatus": Status.OPEN,
        "arrivalTestRequired": false,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.OPEN,
                "overview": "Most visitors from the United States, regardless of vaccination status, can enter the Dominican Republic."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter. fully vaccinated people should have a vaccination certificate that shows they have had the last dose of a vaccine no less than 3 weeks before arrival. Airports and other ports of entry will perform a quick, aleatory breath test on a percentage of passengers, and all those who present symptoms, upon arrival. Passengers under the age of five and crew members are exempt from this procedure."
        ]
    },
    {
        "countryCode": "TL",
        "countryName": "East Timor",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter East Timor."
            }
        ],
        "borderDetails": [
            "Timor-Leste has restricted the entry of all travelers except for nationals and residents of Timor-Leste, passengers born in Timor-Leste, legal representatives of Timorese minors; and oil rig workers."
        ]
    },
    {
        "countryCode": "EC",
        "countryName": "Ecuador",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Ecuador."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Ecuador with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Entry restrictions do not apply to fully vaccinated travelers, travelers under the age of 16, and Ecuadorian citizens who have not yet received their vaccine and require to return to Ecuador will receive the available single-dose vaccine against COVID-19 upon arrival.\n",
            "Travelers must pass through temperature screening and a health evaluation on arrival at the airport."
        ]
    },
    {
        "countryCode": "EG",
        "countryName": "Egypt",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Egypt with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Egypt without restrictions."
            }
        ],
        "borderDetails": [
            "Travelers are required to present a valid health insurance that has COVID-19 coverage upon arrival in Egypt."
        ]
    },
    {
        "countryCode": "SV",
        "countryName": "El Salvador",
        "borderStatus": Status.OPEN,
        "unvaccinatedBorderStatus": Status.OPEN,
        "arrivalTestRequired": false,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.OPEN,
                "overview": "Most visitors from the United States, regardless of vaccination status, can enter El Salvador."
            }
        ],
        "borderDetails": [
            "Most travelers can enter El Salvador, but there are restrictions. \r\n",
            "Travelers may be required to present a valid vaccination certificate to enter specific events."
        ]
    },
    {
        "countryCode": "GQ",
        "countryName": "Equatorial Guinea",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Equatorial Guinea."
            }
        ],
        "borderDetails": [
            "Quarantine and testing is at cost of passengers arriving and leaving. The fees for the PCR test is 50.000 XAF for EG nationals and 110.000 XAF for all other nationals."
        ]
    },
    {
        "countryCode": "ER",
        "countryName": "Eritrea",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Eritrea."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "EE",
        "countryName": "Estonia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Estonia."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Estonia without restrictions."
            }
        ],
        "borderDetails": [
            "Entry restrictions do not apply to fully vaccinated travelers, Estonian citizens, Permanent residents, Diplomats, Essential workers, Transiting residents, Special permit holders, Students in Estonia, parents, children, or spouses of nationals or residents of Estonia, and travelers granted an exemption."
        ]
    },
    {
        "countryCode": "SZ",
        "countryName": "Eswatini",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Eswatini."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "ET",
        "countryName": "Ethiopia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Ethiopia."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Ethiopia with a negative COVID-19 test result."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "FK",
        "countryName": "Falkland Islands (Islas Malvinas)",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": false,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Falkland Islands (Islas Malvinas)."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "FO",
        "countryName": "Faroe Islands",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": false,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter the Faroe Islands."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter the Faroe Islands without restrictions."
            }
        ],
        "borderDetails": [
            "Recognized reasons for foreign nationals residing in banned countries to enter the Faroe Islands include but are not limited to:",
            "\n\n- Residence in the Faroe Islands",
            "\n- Visiting minor children",
            "\n- A work permit and a recognized job or recognized work that needs to be carried out for an employer in the Faroe Islands. Anyone entering the Faroe Islands on this basis is advised to have relevant documentation from their employer (work contractor other certification of employment)",
            "\n- Supply of goods and services to and from the Faroe Islands",
            "\n- Seamen who are signing on or off a vessel in the Faroe Islands. This also applies to flight crew changes, off-shore workers and the like.",
            "\n- Visiting a family member who is critically ill or dying",
            "\n- Attending a funeral",
            "\n- To receive medical treatment",
            "\n- Attending a court hearing",
            "\n- Tourism is not a valid reason"
        ]
    },
    {
        "countryCode": "FM",
        "countryName": "Federated States of Micronesia",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Federated States of Micronesia."
            }
        ],
        "borderDetails": [
            "More information at the <a target=\"_blank\" href=\"https://www.fsmgov.org/index.html\">official site</a>."
        ]
    },
    {
        "countryCode": "FJ",
        "countryName": "Fiji",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Fiji."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Fiji."
            }
        ],
        "borderDetails": [
            "Prior to traveling, all Permit Holders must request Fijian Immigration Department for authority to travel. All request to be email to borderrequest@immigration.gov.fj with the following supporting documents: Passport Bio-Data Page; Permit Letter or Stamp; and Travel Itinerary.\r\n",
            "Dual citizen will need to provide evidence of their Fijian Citizenship status. They will be required to provide one of the following evidences at the check in counter: A valid Fijian Passport, Citizenship Certificate of country of residence."
        ]
    },
    {
        "countryCode": "FI",
        "countryName": "Finland",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Finland."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Finland with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "<b>Effective December 28, 2021 </b> -All travelers who enter Finland are required to present a vaccination or recovery certificate as well as a negative COVID-19 test result taken within 48 hours before entry.\n",
            "<b>Effective December 21, 2021 </b> - All persons who travel from a country outside the EU/Schengen Area will be required to present a negative COVID-19 test result, regardless of vaccination status.",
            "<b>Entry is permitted in the following cases:</b>",
            "1. Return travel to Finland.",
            "2. Return travel to or through other EU and Schengen countries.",
            "3.Entry into Finland from certain specified countries and regions.",
            "4. Essential reasons will be assessed on a case-by-case basis based on the information gathered during the border check.",
            "5. Special groups (such as representatives of culture, sports and business life).",
            "Permitted travelers are recommended to login to the <a target=\"_blank\" href=\"https://www.finentry.fi/en/\">Finentry Web Service </a>before travel.",
            "More information is available at the <a target=\"_blank\" href=\"https://raja.fi/en/guidelines-for-border-traffic-during-pandemic \">Finnish Border Guard</a> website.\n"
        ]
    },
    {
        "countryCode": "FR",
        "countryName": "France",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter France."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter France with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Unvaccinated travelers from orange and red category can travel to France provided that they have compelling reasons or pressing grounds.\r\n\r\n",
            "Travelers are advised to download and activate the <a target=\"_blank\" href=\"https://www.gouvernement.fr/info-coronavirus/tousanticovid\">TousAntiCovid application</a> while staying in the country.",
            "\r\n\r\nFurther details regarding entry to France and the category of countries in France can be found <a target=\"_blank\" href=\"https://www.diplomatie.gouv.fr/fr/conseils-aux-voyageurs/informations-pratiques/article/coronavirus-covid-19\">here</a>."
        ]
    },
    {
        "countryCode": "GF",
        "countryName": "French Guiana",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter French Guiana."
            }
        ],
        "borderDetails": [
            "Only travelers who can prove that entry is for urgent family/work reasons will be allowed to enter, and they will be expected to fill in a <a target=\"_blank\" href=\"https://www.interieur.gouv.fr/Actualites/L-actu-du-Ministere/Attestation-de-deplacement-et-de-voyage\">travel certificate</a> and provide proof."
        ]
    },
    {
        "countryCode": "PF",
        "countryName": "French Polynesia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter French Polynesia."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter French Polynesia with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Entry restrictions do not apply to travelers who are fully vaccinated, travelers who have recovered from COVID-19 and have received at least one vaccine dose between 2 and 6 months after recovering; or who have contracted COVID-19 more than 15 days after receiving at least one vaccine dose, and travelers with compelling reasons.\n",
            "Travelers who are arriving from and have stayed in these high-risk countries for at least 15 days before arrival are not allowed to enter French Polynesia. Only travelers with essential purposes are allowed to enter. Entry restrictions do not apply to travelers with compelling reasons."
        ]
    },
    {
        "countryCode": "GA",
        "countryName": "Gabon",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Gabon."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Gabon with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Travelers can enter Gabon by air. Land and sea borders are closed."
        ]
    },
    {
        "countryCode": "GM",
        "countryName": "Gambia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter the Gambia."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "GE",
        "countryName": "Georgia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Georgia with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Georgia without restrictions."
            }
        ],
        "borderDetails": [
            "Georgian nationals and Foreign nationals who are fully vaccinated are allowed to enter Georgia without presenting a negative PCR test or filling out the <a target=\"_blank\" href=\"https://registration.gov.ge/pub/form/8_protocol_for_arrivals_in_georgia/tk6157/%E2%80%8B\">Pre-Registration Form.</a>\r\n\r\n",
            "Unvaccinated individuals from <a target=\"_blank\" href=\"https://www.geoconsul.gov.ge/HtmlPage//html/View?id=2131\">these countries</a> are allowed to enter Georgia provided that they transit through or arrive directly from those countries. They must present a negative PCR test and fill out the pre-registration form.",
            "\r\n\r\nFurther details of entry to Georgia can be found <a target=\"_blank\" href=\"https://www.geoconsul.gov.ge/ka/newsFeed/nId/291\">here</a>."
        ]
    },
    {
        "countryCode": "DE",
        "countryName": "Germany",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Germany."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Germany without restrictions."
            }
        ],
        "borderDetails": [
            "All travelers from high-risk areas areas must <a target=\"_blank\" href=\"https://www.einreiseanmeldung.de/\">register online</a> and prove their registration upon their arrival in Germany. They are also required to download the Corona-Warn App and will be subject to health screening.\n\n",
            "All travelers aged 12 and over who are permitted to enter Germany must present a negative test result, proof of vaccination, or proof of recovery.",
            "Entry is possible from EU member states, States associated with Schengen and other countries from which entry is possible in light of the epidemiological situation assessment by the EU.",
            "Entry from any other country for any purpose is only possible for fully vaccinated people. For people not yet vaccinated, entry from other countries is only possible in exceptional cases and is conditional on there being an urgent need.",
            "Please check the <a target=\"_blank\" href=\"https://www.bmi.bund.de/SharedDocs/faqs/EN/topics/civil-protection/coronavirus/travel-restrictions-border-control/travel-restriction-border-control-list.html#f13919566\">BMI</a> before travel to find out what regulations apply specifically with regard to the country from which you plan to enter Germany."
        ]
    },
    {
        "countryCode": "GH",
        "countryName": "Ghana",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Ghana."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Ghana with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "The entry restrictions do not apply to travelers aged 17 and under and travelers who are fully vaccinated against COVID-19. Temperature screening is required for all passengers on arrival."
        ]
    },
    {
        "countryCode": "GI",
        "countryName": "Gibraltar",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Gibraltar."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States need to quarantine to enter Gibraltar."
            }
        ],
        "borderDetails": [
            "No person who has been in a red list country in the 10-day period preceding their date of arrival into Gibraltar may enter Gibraltar. The following people are exempt provided that they are able to prove to the satisfaction of an immigration officer that the person:",
            "* falls within the definition of a Gibraltarian pursuant to section 4 of the Gibraltarian Status Act;",
            "\n* is a lawful resident of Gibraltar;",
            "* is a seafarer who-\n-is on transit to a vessel which is berthed or at anchor within British Gibraltar Territorial Waters;\n-holds a valid seafarer’s book, and o has a letter of guarantee from a shipping agent registered in Gibraltar;",
            "\n* is a Spanish national and is on transit to Spain;",
            "\n* intends to transit Gibraltar for the purposes of entering Spain and the person-\n- is lawfully resident in Spain, and o is or will be allowed to enter Spain from Gibraltar;",
            "\n* is carrying out a work activity in Gibraltar or who are taking up employment in Gibraltar;",
            "\n* is a member of the armed forces and the person has been issued with a travel order that states that Gibraltar is the person’s destination;",
            "\n\n* has, in exceptional circumstances, been granted permission by the Borders and Coastguard Agency to enter Gibraltar and is the holder of a letter, electronic or otherwise, issued by the Borders and Coast Guard Agency granting the person leave to enter Gibraltar."
        ]
    },
    {
        "countryCode": "GR",
        "countryName": "Greece",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Greece."
            }
        ],
        "borderDetails": [
            "Travelers are allowed if they are in an essential category such as healthcare professionals, students, government members, diplomats, military personnel, humanitarian aid workers, airline crew, seasonal workers, travelers in transit, or travelers entering Greece for reasons authorized by the Greek embassy or consulate. The list of categories can be found <a target=\"_blank\" href=\"https://travel.gov.gr/#/\">here</a>."
        ]
    },
    {
        "countryCode": "GL",
        "countryName": "Greenland",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Greenland."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Greenland without restrictions."
            }
        ],
        "borderDetails": [
            "Only the following persons can travel to Greenland:",
            "Fully vaccinated passengers 18 years and older 2 vaccine shots, only Pfizer, Moderna, Johnson and Johnson or AstraZeneca; Persons that have previously been infected with COVID19 18 years and older, able to show proof of a positive PCR test result that is at least 14 days old, but no more than 12 weeks old, at the time of travel; passengers with residence in Greenland; passengers with residence outside Greenland from 2 to 17 years old; infants less than 2 years old; All passengers 2 years and older, need to show a negative PCR test taken in a Nordic country DK, SE, NO, FI, FO or IS less than 48h old at time of check in.",
            " A medical or FFP2 face mask is mandatory for airports and flights,A fully vaccinated person is in this case a person who has received both Covid-19 vaccinations and you must be fully vaccinated 14 days prior to departure to Greenland. Greenland accepts people who have a combination of Covid vaccines."
        ]
    },
    {
        "countryCode": "GD",
        "countryName": "Grenada",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Grenada."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Grenada."
            }
        ],
        "borderDetails": [
            "All travelers must have a printed or electronic copy <a target=\"_blank\" href=\"https://travelauth.health.gov.gd/\">Pure Safe Travel Certificate.</a> The application must be submitted no later than 48 hours prior to the date of travel.\r\n",
            "Travelers are required to have a confirmed reservation at an <a target=\"_blank\" href=\"https://www.puregrenada.com/pure-safe-travels/\">Approved Accommodation</a> or Apply for Home Quarantine at least 7 days prior to travel. Home Quarantine requests are made via the Pure Safe Travel portal.",
            "Travelers must download contact tracing app <a target=\"_blank\" href=\"https://play.google.com/store/apps/details?id=com.outsys.covidsupportapp\">\"Ronatrac Mobile App\"</a> and register prior to travel, the app is not available for iPhone users yet, and they are currently exempt from this requirement."
        ]
    },
    {
        "countryCode": "GP",
        "countryName": "Guadeloupe",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Guadeloupe."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Guadeloupe with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Entry restrictions do not apply to travelers with evidence of a compelling reason for travel, such as a personal or family reason, emergency health reason, or professional reasons that cannot be postponed."
        ]
    },
    {
        "countryCode": "GU",
        "countryName": "Guam",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": false,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Guam."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Guam without restrictions."
            }
        ],
        "borderDetails": [
            "Travelers arriving from other US territories and states are allowed to enter Guam."
        ]
    },
    {
        "countryCode": "GT",
        "countryName": "Guatemala",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Guatemala."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Guatemala with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Most unvaccinated travelers are not allowed to enter Guatemala. Entry restrictions do not apply to Guatemalan citizens and residents, travelers under the age of 12 and travelers with a valid medical certificate showing a medical contraindication that makes it impossible to vaccinate against COVID-19.\n",
            "International travelers who have been in South Africa, Botswana, Egypt, Eswatini, Lesotho, Mozambique, Namibia, Zimbabwe for the last 14 days are not allowed to enter Guatemala. Guatemalan citizens, permanent and temporary residents and diplomats accredited in Guatemala are exempted."
        ]
    },
    {
        "countryCode": "GN",
        "countryName": "Guinea",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Guinea."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "GW",
        "countryName": "Guinea-Bissau",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Guinea-Bissau."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "GY",
        "countryName": "Guyana",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Guyana."
            }
        ],
        "borderDetails": [
            "Travelers must complete an online <a target=\"_blank\" href=\"https://guyanatravel.gy/passenger-locator-form/\">Passenger Locator Form</a> at least 24 hours before arrival. Travelers should carry a printed copy of their Passenger Locator Form and their negative PCR COVID-19 test results for the airline at check-in."
        ]
    },
    {
        "countryCode": "HT",
        "countryName": "Haiti",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Haiti."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "HN",
        "countryName": "Honduras",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Honduras with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Honduras without restrictions."
            }
        ],
        "borderDetails": [
            "Travelers are required to complete the <a target=\"_blank\" href=\"https://prechequeo.inm.gob.hn/Login\">pre-check form</a> prior to boarding schedule. Once submitted, the confirmation must be printed and brought while traveling."
        ]
    },
    {
        "countryCode": "HK",
        "countryName": "Hong Kong",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Hong Kong."
            }
        ],
        "borderDetails": [
            "Travelers who have stayed in risk countries for 21 days before traveling to Hong Kong are not allowed to enter. Entry restrictions do not apply to fully vaccinated Hong Kong residents with a recognized vaccination record, children under the age of 12, accompanied by fully vaccinated parents who are residents of Hong Kong, and vaccinated spouses of Hong Kong residents with the required documentation.\n",
            "Most unvaccinated international travelers are not allowed to enter Hong Kong. Travelers holding a visa with a validity of fewer than 14 days will be denied entry. Entry restrictions do not apply to Hong Kong residents, fully vaccinated non-Hong Kong residents, Spouses and children of Hong Kong residents, and Chinese citizens.",
            "Travelers who have spent more than 2 hours in the Philippines, Australia, Canada, France, India, Pakistan, United Kingdom, United States prior to traveling to Hong Kong are not allowed to enter. Flights from these countries are suspended until at least February 4th, 2022.",
            "Temperature screening is required for all travelers on arrival. Travelers with a high temperature will undergo further checks."
        ]
    },
    {
        "countryCode": "HU",
        "countryName": "Hungary",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Hungary."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Hungary without restrictions."
            }
        ],
        "borderDetails": [
            "Foreign nationals who will enter Hungary will be authorized by the Hungarian police agency provided that they have equitable grounds. The application to enter Hungary may be submitted only by electronic means using the relevant <a target=\"_blank\" href=\"https://ugyintezes.police.hu/en/meltanyossagi-kerelem\">electronic forms</a> in Hungarian or English. The original copy of the submitted document must be presented upon entry to the territory of Hungary.\r\n\r\n",
            "More details in entering Hungary can be found <a target=\"_blank\" href=\"https://visithungary.com/articles/entry-rules-in-force\">here</a> and on this <a target=\"_blank\" href=\"http://www.police.hu/en/content/information-on-general-rules-of-border-crossing\">site</a>."
        ]
    },
    {
        "countryCode": "IS",
        "countryName": "Iceland",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Iceland."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Iceland with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Travelers entering Iceland must <a target=\"_blank\" href=\"https://heimkoma.covid.is/\">pre-register</a> prior to arrival to the country. They are obliged to confirm their departure date if it is available.\r\n\r\n\r\n",
            "Further details about entry to Iceland can be found <a target=\"_blank\" href=\"https://www.covid.is/sub-categories/travel-to-and-within-iceland\">here.</a>"
        ]
    },
    {
        "countryCode": "IN",
        "countryName": "India",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter India."
            }
        ],
        "borderDetails": [
            "All travelers going to India should submit their self-declaration form and upload their negative Covid-19 RT PCR test result in the <a target=\"_blank\" href=\"https://www.newdelhiairport.in \">Air Suvidha Portal</a> before the scheduled travel. Travelers shall also submit a declaration with respect to the authenticity of the report and will be liable for criminal prosecution if found otherwise. \n\n",
            "Learn more information on the \n<a target=\"_blank\" href=\"https://www.mohfw.gov.in/pdf/RevisedGuidelinesforInternationalArrivalsdated7thJanuary2022.pdf \">Guidelines for International Arrivals</a> issued by India's Ministry of Health and Family Welfare."
        ]
    },
    {
        "countryCode": "ID",
        "countryName": "Indonesia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Indonesia."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Indonesia."
            }
        ],
        "borderDetails": [
            "Entry restrictions do not apply to Indonesian citizens, holders of KITAS, KITAP, Visitor Visas, diplomatic and official / service visa and stay permit holders, travelers permitted under the TCA bilateral agreement scheme, foreign nationals with written permission from relevant ministries and government institutes. Those who are eligible should reconfirm their eligibility by contacting the <a target=\"_blank\" href=\"https://www.imigrasi.go.id/en/covid19-1/ \">Indonesian Embassy</a>.\r\n",
            "Permitted travelers are required to present a card or certificate (physical or digital) that they have received the full dose of COVID-19 vaccine. Foreigners under the age of 12 years who are traveling with their parents are exempted from this requirement."
        ]
    },
    {
        "countryCode": "IR",
        "countryName": "Iran",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Iran."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Iran with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Entry is permitted to holders of an Iranian passport or holders of a valid visa."
        ]
    },
    {
        "countryCode": "IQ",
        "countryName": "Iraq",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Iraq."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "IE",
        "countryName": "Ireland",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Ireland with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Ireland without restrictions."
            }
        ],
        "borderDetails": [
            "All travelers aged 18 and above who are going to Ireland are required to complete the <a target=\"_blank\" href=\"https://travel.eplf.gov.ie/en\">COVID-19 Passenger Locator Form</a> before arrival to the country. Details of children under the age of 18 should be included on a form completed by an adult accompanying them.\r\n\r\n",
            "Diplomats and supply chain services like haulers, pilots and maritime staff are exempted from completing the said form.",
            "More details on Ireland entry can be found <a target=\"_blank\" href=\"https://www.gov.ie/en/publication/77952-government-advice-on-international-travel/\">here</a>. "
        ]
    },
    {
        "countryCode": "IL",
        "countryName": "Israel",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Israel."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Israel."
            }
        ],
        "borderDetails": [
            "Foreign nationals may enter Israel either through Ben Gurion International Airport or Ramon Airport provided that they are <a target=\"_blank\" href=\"https://corona.health.gov.il/en/vaccine-for-covid/fully-vaccinated/\">vaccinated</a> and <a target=\"_blank\" href=\"https://corona.health.gov.il/en/confirmed-cases-and-patients/cases-recovered/\">they recovered from COVID-19</a> disease.\r\n\r\n",
            "Unvaccinated foreign citizens are only allowed to enter the country if they have a special entry permit from the <a target=\"_blank\" href=\"https://govforms.gov.il/mw/forms/knisalisrael%40mofa.gov.il#!requestDetails\">Exceptions Committee</a>.",
            "Details of entry in Israel can be found <a target=\"_blank\" href=\"https://corona.health.gov.il/en/directives/air-travel-covid19-green/?tab=by-air\">here</a>."
        ]
    },
    {
        "countryCode": "IT",
        "countryName": "Italy",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Italy."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Italy with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Most travelers who have been in risk countries in the previous 14 days before arrival are not allowed to enter Italy. This entry restriction does not apply to Italian Citizens and lawful permanent residents, Citizens of the European Union or the Schengen area and their family members, as well as holders of long-term resident status and their family members, Travelers who have a proven and stable emotional relationship (even if not cohabiting) with Italian/EU/Schengen citizens or natural persons who are legally resident in Italy (long-term residents), and who need to reach their partner's home/domicile/residence in Italy, and Travelers with work, health, study reasons as well as with urgent matters and those returning home.\n",
            "Travelers allowed entry must communicate their entry into Italy to the Department of Prevention of the territorially competent Health Authority."
        ]
    },
    {
        "countryCode": "CI",
        "countryName": "Ivory Coast",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Ivory Coast."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "JM",
        "countryName": "Jamaica",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Jamaica."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Jamaica with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Travelers are reminded to observe the government's <a target=\"_blank\" href=\"https://www.visitjamaica.com/travelauthorization/traveller-categories/\">Controlled Entry Programme protocols</a> regarding quarantine."
        ]
    },
    {
        "countryCode": "JP",
        "countryName": "Japan",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Japan."
            }
        ],
        "borderDetails": [
            "As emergency precautionary measure from a preventive perspective, new entry of foreign nationals is suspended for the time being. Foreign nationals who are entitled to enter Japan as those with special exceptional circumstances are required to apply for an appropriate visa at Embassies or Consulates or Consular Office of Japan in their respective country/region.\n\n",
            "Entry Restriction does not apply to Japanese nationals, holders of permanent or long-term residency permits with a re-entry permit or re-entry confirmation letter, spouses, or children of Japanese citizens or permanent residents.",
            "For more details visit the <a target=\"_blank\" href=\"https://www.mofa.go.jp/ca/fna/page4e_001053.html#section1\">Japan Ministry of Foreign Affairs</a>."
        ]
    },
    {
        "countryCode": "JE",
        "countryName": "Jersey",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Jersey."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter Jersey by air."
        ]
    },
    {
        "countryCode": "JO",
        "countryName": "Jordan",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Jordan."
            }
        ],
        "borderDetails": [
            "Most travelers can enter Jordan, but there are restrictions.\n",
            "Travelers who are nationals or from the restricted list of countries are not allowed to enter Jordan. This restriction does not apply to travelers with a resident permit issued by Jordan, travelers with a residence permit valid for a minimum of 6 months from the arrival date issued by Australia, Austria, Bahrain, Belgium, Bulgaria, Canada, Croatia, Cyprus, Czechia, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Japan, Kuwait, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Oman, Poland, Portugal, Qatar, Romania, Saudi Arabia, Slovakia, Slovenia, Spain, Sweden, United States, United Arab Emirates, United Kingdom and who have a return/onward tickets, travelers who are nationals of Comoros, Djibouti, Iraq, Mauritania, Morocco, or Sudan residing in Turkey and travelers with a visa issued by Jordan."
        ]
    },
    {
        "countryCode": "KZ",
        "countryName": "Kazakhstan",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Kazakhstan."
            }
        ],
        "borderDetails": [
            "These travel restrictions do not apply to:\n",
            "<ul>\n<li>Nationals of Kazakhstan\n<li>Residents of Kazakhstan (may enter once every 30 days)\n<li>Spouse, parents, children of nationals/residents of Kazakhstan (may enter once every 30 days)\n<li>Nationals of Armenia, Belarus, Egypt, Georgia, Germany, South Korea, Kyrgyzstan, Montenegro, Netherlands, Qatar, Russia, Tajikistan, Turkey, Ukraine, United Arab Emirates, and Uzbekistan\n<li>Diplomatic passport holders and their families\n<li>People with Kazakhstan visas and approved by Deputy Prime Minister of Kazakhstan\n<li>Passengers traveling as students with an invitation letter from an educational institution of Kazakhstan\n<li>Passengers traveling on business and approved by the Deputy Prime Minister of Kazakhstan</ul>"
        ]
    },
    {
        "countryCode": "KE",
        "countryName": "Kenya",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Kenya."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Kenya with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Unvaccinated travelers are not allowed to enter Kenya. The restrictions do not apply to Kenyan citizens travelers under the age of 18, fully vaccinated travelers with a valid vaccination certificate, and ravelers who have recovered from COVID-19 with accepted proof. Travelers will be screened for flu-like or clinical symptoms on arrival."
        ]
    },
    {
        "countryCode": "KI",
        "countryName": "Kiribati",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Kiribati."
            }
        ],
        "borderDetails": [
            "Kiribati is denying entry to any travelers who have been in or transited countries with confirmed local transmission within 14 days immediately prior to entering Kiribati. A list of countries covered by these restrictions is available from the <a target=\"_blank\" href=\"https://mhms.gov.ki/\">Kiribati Ministry of Health and Medical Services.</a>"
        ]
    },
    {
        "countryCode": "XK",
        "countryName": "Kosovo",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Kosovo with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Kosovo without restrictions."
            }
        ],
        "borderDetails": [
            "Most travelers can enter Kosovo, but there are restrictions."
        ]
    },
    {
        "countryCode": "KW",
        "countryName": "Kuwait",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Kuwait."
            }
        ],
        "borderDetails": [
            "Kuwait has restricted the entry of all travelers except Kuwaiti nationals, their immediate families, and domestic workers who are accompanied by a Kuwaiti national. All of the above must have OK TO TRAVEL authorization obtained from the state of Kuwait embassy.\r\n",
            "All travelers must install the \"Shlonik\" and \"Kuwait-Mosafer\" app on their personal device before departure. Kuwait DGCA will be informed of any traveler who cannot install the app, and they will have to present their boarding pass upon arrival.",
            "Travelers must register <a target=\"_blank\" href=\"https://kuwaitmosafer.gov.kw/home.html\">online</a> prior to departure."
        ]
    },
    {
        "countryCode": "KG",
        "countryName": "Kyrgyzstan",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Kyrgyzstan."
            }
        ],
        "borderDetails": [
            "Foreign citizens who are specialist workers on W1 and W2 visas are also now able to enter Kyrgyzstan, though their family members may not be eligible. \r\nFor any questions on entry or exit requirements, you should contact the relevant Kyrgyz authorities on +996 312 881 618."
        ]
    },
    {
        "countryCode": "LA",
        "countryName": "Laos",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Laos."
            }
        ],
        "borderDetails": [
            "is permitted for urgent and essential reasons only:\r\nExceptions are in place for essential travel reasons such as:\r\n<ul>work;</ul>\r\n<ul>training, studies;</ul>\r\n<ul>family reunification;</ul>\r\n<ul>to receive medical services;</ul>\r\n<ul>transit;</ul>\r\n<ul>accompanying a minor;</ul>\r\n<ul>return to one’s permanent place of residence;</ul>\r\n<ul>attending a funeral.</ul>"
        ]
    },
    {
        "countryCode": "LV",
        "countryName": "Latvia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Latvia."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Latvia without restrictions."
            }
        ],
        "borderDetails": [
            "Travelers arriving in Latvia are required to fill out the <a target=\"_blank\" href=\"https://covidpass.lv/\">Covidpass</a> 48 hours prior to arrival in Latvia."
        ]
    },
    {
        "countryCode": "LB",
        "countryName": "Lebanon",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Lebanon."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter Lebanon, but there are restrictions."
        ]
    },
    {
        "countryCode": "LS",
        "countryName": "Lesotho",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Lesotho."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "LR",
        "countryName": "Liberia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Liberia."
            }
        ],
        "borderDetails": [
            "All travelers must download the Lib Travel mobile app for <a target=\"_blank\" \r\n\r\nhref=\"https://play.google.com/store/apps/details?id=com.tuma.libtravel\">iOS-Android</a> prior to arrival. ",
            "Exempt travelers includes arriving diplomats and their families, honorary consuls, duly accredited international organizations, and Liberian diplomats. Exempt passengers should follow the protocol set by their respective organizations. Exempt travelers are required to complete the Health Screening Arrival Form via the Lib Travel app.",
            " All cabin crew, captains, flight engineers, and first officers with layovers less than 48 hours will be exempted from testing if they complete pre-boarding testing per their airline’s policy and comply with symptom screening procedures while in Liberia. On arrival at the airport they will complete screening and proceed to their designated flight crew hotel."
        ]
    },
    {
        "countryCode": "LY",
        "countryName": "Libya",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Libya."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "LI",
        "countryName": "Liechtenstein",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Liechtenstein."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "LT",
        "countryName": "Lithuania",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Lithuania."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Lithuania without restrictions."
            }
        ],
        "borderDetails": [
            "Entry restrictions do not apply to vaccinated travelers, citizens and legal residents of the European Economic Area, Switzerland, UK, Andorra, Monaco, San Marino, and Vatican City, travelers with proof of residency in Lithuania, family members of a citizen or legal resident of Lithuania such as a spouse, registered partner, parents, children, siblings, grandparents, and grandchildren and National visa holders."
        ]
    },
    {
        "countryCode": "LU",
        "countryName": "Luxembourg",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Luxembourg with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Luxembourg without restrictions."
            }
        ],
        "borderDetails": [
            "<b>Travel restrictions do not apply to the following:</b>\n",
            "1. Citizens of the EU, and Schengen associated countries including their family members",
            "2. Long-term EU residents and their family members",
            "3. Travelers with an essential function or need",
            "Check the <a target=\"_blank\" href=\"https://guichet.public.lu/en/actualites/2020/aout/11-nouvelles-mesures-entree-ressortissant-tiers.html\">Government of Luxembourg</a> website for the full list of exemptions.",
            "<b>Permitted travelers, aged 12 years and 2 months or older, must present any of the following upon boarding:</b>",
            "1. Recognized vaccination certificate",
            "2. Certificate of recovery",
            "3. Negative result of a biological examination for COVID-19 : Tests for the amplification of SARS-CoV-2 viral RNA (PCR, TMA or LAMP methods) or viral antigen tests are accepted."
        ]
    },
    {
        "countryCode": "MO",
        "countryName": "Macau",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Macau."
            }
        ],
        "borderDetails": [
            "Travelers who are residents of mainland China, Hong Kong, and Taiwan are allowed to enter Macao if they have been in Philippines, Brazil, India, Nepal, and Pakistan 28 days prior to arrival."
        ]
    },
    {
        "countryCode": "MG",
        "countryName": "Madagascar",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Madagascar."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Madagascar."
            }
        ],
        "borderDetails": [
            "Entry is limited to diplomats, technicians working in strategic sectors, and civil servants with special travel orders. Permitted travelers are required to fill out the <a target=\"_blank\" href=\"https://madagascar-tourisme.com/wp-content/uploads/2021/11/FICHE-DE-DEBARQUEMENT.pdf\">Boarding Form</a> and must meet the below travel conditions to enter Madagascar: \n\n",
            "1. Presentation of a negative result of an RT-PCR test performed 72 hours before boarding",
            "2. Presentation of a <a target=\"_blank\" href=\"https://madagascar-tourisme.com/wp-content/uploads/2021/11/Commitment-letter.pdf \">Letter of Commitment</a> to stay in self-isolation until the test result is obtained",
            "4. Presentation of 2 nights hotel booking certification and proof of payment of a hotel room for at least 1 night for self-isolation. See list of accredited hotels <a target=\"_blank\" href=\"https://madagascar-tourisme.com/en/border-reopening/\">HERE</a>.",
            "5. Proof of Airport-to-hotel transport that is organised by the accommodating hotel and is payable by the traveller.\n",
            "4. The completion of a travel tracking form after the self-isolation period.",
            "\n\nThe requirements for travel to Madagascar apply to all passengers without exception."
        ]
    },
    {
        "countryCode": "MW",
        "countryName": "Malawi",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Malawi."
            }
        ],
        "borderDetails": [
            "Travelers are not allowed to enter Malawi. These entry restrictions do not apply to Malawi citizens and residents, their spouses, children, and dependents."
        ]
    },
    {
        "countryCode": "MY",
        "countryName": "Malaysia",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Malaysia."
            }
        ],
        "borderDetails": [
            "Entry restrictions do not apply to Malaysian citizens, residents, diplomats, foreign spouses and dependents of Malaysian citizens, long-term pass holders, and expatriate employees working in essential industries.",
            "Unvaccinated children aged 12 and under are eligible to enter Malaysia on the VTL if accompanied by a fully vaccinated VTL traveler. Vaccinated short-term travelers are required to have travel insurance with a minimum coverage of RM100,000 for COVID-19 related medical treatment and hospitalization costs, before traveling from Singapore for the entire duration of their stay in Malaysia. traveler must bring a copy of their insurance policy "
        ]
    },
    {
        "countryCode": "MV",
        "countryName": "Maldives",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter the Maldives."
            }
        ],
        "borderDetails": [
            "Permitted travelers who are going to Maldives must fill in a <a target=\"_blank\" href=\"https://imuga.immigration.gov.mv/ethd/create\">Traveller Health Declaration (THD)</a> within 48 hours prior to entry to the country.\r\n",
            "Tourists must have a confirmed booking at a registered tourist facility during their entire stay in the country."
        ]
    },
    {
        "countryCode": "ML",
        "countryName": "Mali",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Mali."
            }
        ],
        "borderDetails": [
            "Travelers can enter Mali, but there are restrictions."
        ]
    },
    {
        "countryCode": "MT",
        "countryName": "Malta",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Malta."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States need to quarantine to enter Malta."
            }
        ],
        "borderDetails": [
            "Travel to Malta is only permitted to residents of countries included in the list under the <a target=\"_blank\" href=\"https://legislation.mt/eli/sl/465.26/20211021/eng\">SUBSIDIARY LEGISLATION 465.26</a> if one can present a <a target=\"_blank\" href=\"https://deputyprimeminister.gov.mt/en/health-promotion/covid-19/Pages/travel.aspx\">valid Vaccination Certificate</a>. \nThis applies to anyone age 12 and older. \n\n",
            "More information about entering Malta can be found in the <a target=\"_blank\" href=\"https://www.visitmalta.com/en/info/new-covid-19-updates/\">Visit Malta website.</a>"
        ]
    },
    {
        "countryCode": "MH",
        "countryName": "Marshall Islands",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": false,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter the Marshall Islands."
            }
        ],
        "borderDetails": [
            "Marshall Islands authorities have introduced a number of precautionary measures, including a total suspension of international travellers coming into RMI. Domestic air travel between Majuro and Kwajalein on international airlines is also no longer permitted."
        ]
    },
    {
        "countryCode": "MQ",
        "countryName": "Martinique",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Martinique."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Martinique with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Entry to Martinique for unvaccinated travelers is allowed provided that they have compelling reasons (personal or family reason; emergency health reason; and/or professional reason that cannot be postponed)."
        ]
    },
    {
        "countryCode": "MR",
        "countryName": "Mauritania",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Mauritania."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter Mauritania. Limited commercial flights are available."
        ]
    },
    {
        "countryCode": "MU",
        "countryName": "Mauritius",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Mauritius."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Mauritius with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "All travelers, except Mauritian citizens, resident permit holders and occupational permit holders are required to present an insurance with COVID-19 coverage. \r\n",
            " Unvaccinated travelers are required to book a <a target=\"_blank\" href=\"https://mauritiusnow.com/where-to-stay-in-mauritius/\">quarantine hotel</a> for 14 nights. Meals and transfers must be included in their hotel booking.",
            "\r\n\r\nMore details can be found <a target=\"_blank\" href=\"https://mauritiusnow.com/\">here</a>."
        ]
    },
    {
        "countryCode": "YT",
        "countryName": "Mayotte",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Mayotte."
            }
        ],
        "borderDetails": [
            "Travel to Mayotte is severely restricted until further notice. Only travelers who can prove that entry is for urgent family/work reasons will be allowed to enter, and they will be expected to fill in a travel certificate and provide proof."
        ]
    },
    {
        "countryCode": "MX",
        "countryName": "Mexico",
        "borderStatus": Status.OPEN,
        "unvaccinatedBorderStatus": Status.OPEN,
        "arrivalTestRequired": false,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.OPEN,
                "overview": "Most visitors from the United States, regardless of vaccination status, can enter Mexico."
            }
        ],
        "borderDetails": [
            "Mexico is open to travelers regardless of their vaccination status. \r\n",
            "A COVID-19 test and a quarantine are not required. However, travelers are required to complete the <a target=\"_blank\" href=\"https://www.vuelaseguro.com/login\">Health Declaration Form</a> and scan the QR code it generates on arrival."
        ]
    },
    {
        "countryCode": "MD",
        "countryName": "Moldova",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Moldova."
            }
        ],
        "borderDetails": [
            "Most travelers can enter Moldova, but there are restrictions. Travelers with a travel history in South Africa, Botswana, Eswatini, Lesotho, Malawi, Mozambique, Namibia or Zimbabwe during the 14 days prior to their arrival are not allowed to enter Moldova."
        ]
    },
    {
        "countryCode": "MN",
        "countryName": "Mongolia",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Mongolia."
            }
        ],
        "borderDetails": [
            "Most travelers are not allowed to enter Mongolia. Travelers must apply for a special entry permit from the Mongolian Immigration Agency.",
            " Exemption applies to Mongolian nationals, permanent residents, some long-term visa holders, and other permitted arrivals. Officials may allow foreign nationals to apply for entry visas if they are fully vaccinated against COVID-19. International passenger flights remain restricted."
        ]
    },
    {
        "countryCode": "ME",
        "countryName": "Montenegro",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Montenegro with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Montenegro without restrictions."
            }
        ],
        "borderDetails": [
            "Montenegrin citizens and foreigners are allowed to enter the country if they can present any of the following: \r\n\r\n",
            "1. Certificate of full vaccination by an authorized health institution with the prescribed number of doses. It is valid if the last dose of the vaccine has been inoculated no more than six months;",
            "2. A negative PCR test not older than 72 hours or a negative rapid antigen test not older than 48 hours or;",
            "3. An evidence that they have survived COVID-19 (valid only if it has been no less than 10 days and no more than 6 months since a positive PCR or a rapid antigen test was presented).",
            "Individuals who have compelling reasons and vital jobs such as diplomats, health workers and researchers; drivers who transport passengers and goods; foreign seafarers; military personnel and humanitarians are exempt from Montenegro entry restrictions."
        ]
    },
    {
        "countryCode": "MS",
        "countryName": "Montserrat",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Montserrat."
            }
        ],
        "borderDetails": [
            "All intended arrivals must complete and submit an <a target=\"_blank\" href=\"http://www.gov.ms/register-to-travel-to-montserrat/\">Access Declaration Form</a> at least 72 hours prior to arrival."
        ]
    },
    {
        "countryCode": "MA",
        "countryName": "Morocco",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Morocco."
            }
        ],
        "borderDetails": [
            "Most international travelers are not allowed to enter Morocco. Direct flights to Morocco are suspended until further notice. Temperature and health screening are required for all travelers on arrival in Morocco."
        ]
    },
    {
        "countryCode": "MZ",
        "countryName": "Mozambique",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Mozambique."
            }
        ],
        "borderDetails": [
            "Temperature screening is required for all travelers on arrival at the airports in Mozambique."
        ]
    },
    {
        "countryCode": "MM",
        "countryName": "Myanmar",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Myanmar."
            }
        ],
        "borderDetails": [
            "Foreign nationals who wish to travel to Myanmar by relief or special flights due to urgent official missions or compelling reasons are asked to contact the Myanmar mission for a possible exceptions to certain visa restrictions. Myanmar eVisas, visas on arrival, and visa exemptions are not being issued currently."
        ]
    },
    {
        "countryCode": "NA",
        "countryName": "Namibia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Namibia."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Namibia with a negative COVID-19 test result."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "NR",
        "countryName": "Nauru",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Nauru."
            }
        ],
        "borderDetails": [
            "Only travelers who have spent at least the previous 14 days in the following countries will be considered eligible to enter Nauru:",
            "\r\n\r\nAustralia (not including Victoria), Cook Islands, Fiji, Kiribati, Marshall Islands, Micronesia, New Caledonia, New Zealand, Niue, Palau, Samoa, Solomon Islands, Taiwan, Tonga, Tuvalu and Vanuatu. ",
            " Travelers wishing to enter Nauru from a country not listed above must apply for an exemption from the Nauru Government. Travelers who have transited through, or have been in mainland China, Hong Kong, Macao, Iran, Italy, Korea, Europe, US or Asia (except Taiwan) in the past 21 days, will not be allowed to enter Nauru."
        ]
    },
    {
        "countryCode": "NP",
        "countryName": "Nepal",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Nepal."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Nepal with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Travelers who are not fully vaccinated and unvaccinated travelers must get a visa from Nepali missions abroad and are allowed to enter Nepal only through agencies. Travelers with a travel history in South Africa, Botswana, Eswatini, Lesotho, Malawi, Mozambique, Namibia or Zimbabwe in the previous 3 weeks are not allowed to enter Nepal."
        ]
    },
    {
        "countryCode": "NL",
        "countryName": "Netherlands",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter the Netherlands."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter the Netherlands with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Entry restrictions do not apply to Dutch, EU, and Schengen citizens and legal residents, family members of EU and Schengen citizens with some rules, travelers in transit in a maximum of 48 hours to a non-Schengen country, and fully vaccinated travelers with proof of vaccination.\n",
            "Travelers must have documentation to prove their exemption."
        ]
    },
    {
        "countryCode": "NC",
        "countryName": "New Caledonia",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter New Caledonia."
            }
        ],
        "borderDetails": [
            "Travel restriction does not apply to New Caledonia citizens and residents and travelers who can prove they have an essential reason. Travelers are not allowed to enter New Caledonia. Only flights to and from France and Sydney remain scheduled and are based on overriding personal, family, or professional reasons. Commercial flights are suspended until at least October 30th, 2021."
        ]
    },
    {
        "countryCode": "NZ",
        "countryName": "New Zealand",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter New Zealand."
            }
        ],
        "borderDetails": [
            "Effective January 7, 2022 : Most travelers to New Zealand must have a negative PCR or RT-PCR test within 48 hours of the scheduled departure of their first international flight. Only a PCR or RT-PCR test will be accepted. LAMP or antigen tests will no longer meet the requirements for a pre-departure test to enter New Zealand.\n\n",
            "Entry to New Zealand is allowed provided that a traveler is a partner, a dependent child, or a family member of a student or work visa holder; a temporary visa holder; a resident holder; critical health workers including their partner or independent children; students; ship crew and humanitarians. The <a target=\"_blank\" href=\"https://www.immigration.govt.nz/formshelp/request-for-travel-to-new-zealand\">Request to Travel Form</a> must be filled out prior to entry. ",
            "Travelers do not need to request for an approval if they are New Zealand citizens and permanent resident visa holders; diplomats who hold a post in New Zealand; Australian citizens or permanent residents ordinarily resident in New Zealand and eligible travelers from the Cook Islands, Niue, and Australia.",
            "Travelers who are not coming from a quarantine-free travel zone are required to have a voucher confirming their place in managed isolation before boarding their flight. ",
            "Learn more details about the country's entry rules in the <a target=\"_blank\" href=\" https://www.immigration.govt.nz/about-us/covid-19/border-closures-and-exceptions/entry-to-new-zealand\"> New Zealand Immigration.</a> website."
        ]
    },
    {
        "countryCode": "NI",
        "countryName": "Nicaragua",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Nicaragua."
            }
        ],
        "borderDetails": [
            "Most international travelers are allowed to enter Nicaragua. Some international commercial flights have resumed."
        ]
    },
    {
        "countryCode": "NE",
        "countryName": "Niger",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Niger."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "NG",
        "countryName": "Nigeria",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Nigeria."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Nigeria with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Most travelers can enter Nigeria but there are restrictions."
        ]
    },
    {
        "countryCode": "MP",
        "countryName": "Northern Mariana Islands",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter the Northern Mariana Islands."
            }
        ],
        "borderDetails": [
            "Arriving travelers must complete a Declaration Form online at <a target=\"_blank\" href=\"http://www.governor.gov.mp/covid-19/travel\">www.governor.gov.mp/covid-19/travel</a> at least 3 days before arrival."
        ]
    },
    {
        "countryCode": "KP",
        "countryName": "North Korea",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter North Korea."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "MK",
        "countryName": "North Macedonia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter North Macedonia."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter North Macedonia without restrictions."
            }
        ],
        "borderDetails": [
            "North Macedonia citizens and Foreign citizens can enter the territory of the Republic of North Macedonia provided that they present any of the following:\r\n\r\n<ul>- certificate / confirmation for immunization (vaccination) against the SARS-CoV-2 virus, i.e. administration of at least two doses of vaccine against the infectious disease COVID-19;</ul>\r\n<ul>- certificate / confirmation for having recovered from COVID-19 in the last 45 days, counting from the day of recovery;</ul>\r\n<ul>- medical certificate with negative result from RT PCR SARS-CoV-2 test, made 72 hours before entry to the territory of the Republic of North Macedonia or;</ul>\r\n<ul>- negative result of a rapid antigen test, made 48 hours before entry to the territory of the Republic of North Macedonia.</ul>"
        ]
    },
    {
        "countryCode": "NO",
        "countryName": "Norway",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Norway."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Norway without restrictions."
            }
        ],
        "borderDetails": [
            "All travelers arriving in Norway must complete registration prior to arrival. This also applies to Norwegian citizens. All travelers arriving in Norway must take a test after arrival, regardless of their vaccination status. Except for people under the age of 16."
        ]
    },
    {
        "countryCode": "OM",
        "countryName": "Oman",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Oman."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Oman with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Travelers with a travel history in in the past 14 days before travel are not allowed to enter Oman.",
            "Omani citizens, diplomats, health staff, and their families are exempt from the ban and will be subject to entry procedures adopted by the Sultanate. Restricted entry into the Sultanate to only Omanis and residents, who have visas issued on or before 5th April 2021, and they must comply with the requirements imposed by the relevant authorities.",
            " All foreign nationals must have valid health insurance.",
            "All travelers are required to present proof of vaccination with a QR Code showing that 14 days have passed since they received all the doses required for COVID-19 vaccination.\nVaccines approved in Oman are Pfizer‑BioNTech, Oxford AstraZeneca, Sputnik V, Sinovac, Moderna, Johnson & Johnson, Sinopharm, Covishield AstraZeneca."
        ]
    },
    {
        "countryCode": "PK",
        "countryName": "Pakistan",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Pakistan."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Pakistan with a negative COVID-19 test result."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "PW",
        "countryName": "Palau",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Palau."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Palau with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter Palau provided that they are <a href=\"https://www.palaugov.pw/travel/\" target=\"_blank\">vaccinated</a>, Palauan citizens and Residents."
        ]
    },
    {
        "countryCode": "PS",
        "countryName": "Palestinian Territories",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Palestinian Territories."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "PA",
        "countryName": "Panama",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Panama with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Panama without restrictions."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter Panama. Temperature screening is required for all travelers on arrival in Panama."
        ]
    },
    {
        "countryCode": "PG",
        "countryName": "Papua New Guinea",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Papua New Guinea."
            }
        ],
        "borderDetails": [
            "Travelers must pay and submit a completed application to the COVID-19 Oversight Committee."
        ]
    },
    {
        "countryCode": "PY",
        "countryName": "Paraguay",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Paraguay."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "PE",
        "countryName": "Peru",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Peru with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Peru without restrictions."
            }
        ],
        "borderDetails": [
            "Travelers arriving from South Africa or Botswana, or who have made a stopover in the said countries in the last 14 days are not allowed to enter Peru until 30 January 2022. This restriction does not apply to Peruvians and foreign residents of Peru."
        ]
    },
    {
        "countryCode": "PH",
        "countryName": "Philippines",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter the Philippines."
            }
        ],
        "borderDetails": [
            "Most travelers are not allowed to enter the Philippines. Most travelers must have a valid existing visa to be allowed entry into the Philipines. This entry restrictions do not apply to Filipino citizens, including those with an Identification Certificate, travelers under the Balikbayan Program who fulfill other specific program requirements, travelers who are holders of valid and existing Special Resident and Retirees Visa or Section 9 visas without the need of an entry exemption document, Foreign spouses, parent, or children of Filipino citizens, not traveling together with the Filipino and shall present a visa with notation “EED not required per IATF Resolution No. 128 (s.2021)”.",
            " Inbound international travel shall not be allowed for all persons, regardless of vaccination status, coming from or who have been to Red List countries/regions within the last 14 days prior to arrival to any port of the Philippines",
            " For more info please click <a target=\"_blank\" href=\"https://philippines.travel/safetrip#inboundadvisory\">here.</a>"
        ]
    },
    {
        "countryCode": "PL",
        "countryName": "Poland",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Poland."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Poland with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Flights from Botswana, Eswatini, Lesotho, Mozambique, Namibia, South Africa, Zimbabwe are suspended until 24 January 2022.\r\n",
            "Entry to Poland from countries other than the aforementioned countries is applicable to Polish citizens, EU and EFTA citizens, foreign nationals married to a Polish citizen, foreigners with Pole’s card, diplomats, foreigners with the right of permanent or temporary residence, Individuals working in Poland, students and other individuals who have a compelling reason to enter Poland.",
            "Travelers entering Poland by plane are required to fill out the <a target=\"_blank\" href=\"https://aplikacje.gov.pl/app/klp/#/home\">Traveler Location Card</a> prior to their flight schedule."
        ]
    },
    {
        "countryCode": "PT",
        "countryName": "Portugal",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Portugal."
            }
        ],
        "borderDetails": [
            "As of December 1, 2021 - An obligation to show a negative test applies to all arrivals, regardless of vaccination status.\n\n",
            "Travelers from closed areas may enter or transit through Portugal only if they have an \"essential\" reason such as professional, study, family reunification, health, or humanitarian reasons.",
            "\nTravelers, except children under the age of 12, may be allowed to enter Portugal with an EU Covid Digital Certificate, vaccination certificate, or recovery certificate. Acceptable vaccines include AstraZeneca, Moderna, Pfizer/BioNTech, and Johnson and Johnson. ",
            "\nThird-country national holders of residency permits or long-term resident visas issued by EU member states may transit Portugal to return to their country of residence. ",
            "\nAll arrivals must complete a <a target=\"_blank\" href=\"https://portugalcleanandsafe.pt/en/passenger-locator-card\">passenger locator form</a> in advance. ",
            "\n\nYou may visit the <a target=\"_blank\" href=https://www.visitportugal.com/en/content/covid-19-measures-implemented-portugal>National Tourism Board</a> website for more details on Portugal's border restrictions."
        ]
    },
    {
        "countryCode": "PR",
        "countryName": "Puerto Rico",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Puerto Rico."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Puerto Rico with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Beginning December 6, 2021 - all travelers arriving on international flights must present a negative COVID-19 test taken within one day prior to departure along with proof of vaccination. Please note that only FDA-approved vaccinations will be accepted, which include Pfizer-BioNTech, Modern, and Janssen/Johnson & Johnson. \n",
            "If you are not fully vaccinated against COVID-19, you will NOT be allowed to board a flight to the United States and its territories, unless you meet the criteria for an exception under the Proclamation and <a target=\"_blank\" href=\"https://www.cdc.gov/coronavirus/2019-ncov/travelers/proof-of-vaccination.html#noncitizen\">CDC Order</a>."
        ]
    },
    {
        "countryCode": "QA",
        "countryName": "Qatar",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Qatar."
            }
        ],
        "borderDetails": [
            "Most travelers can enter Qatar, but there are restrictions. \n",
            "Travelers from Bangladesh, Botswana, Egypt, India, Lesotho, Namibia, Nepal, Pakistan, or Zimbabwe are not allowed to enter Qatar. These restrictions do not apply to Fully vaccinated travelers, Qatari citizens, Qatari residents, and GCC citizens."
        ]
    },
    {
        "countryCode": "CG",
        "countryName": "Republic of the Congo",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Republic of the Congo."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter the Republic of the Congo by air. Land and river borders remain closed. Temperature screening is required for all travelers on arrival."
        ]
    },
    {
        "countryCode": "RE",
        "countryName": "Réunion",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Réunion."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Réunion with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Fully vaccinated individuals can enter Reunion. Otherwise, travelers need to have a compelling reason so they can travel to the country.\r\n\r\n",
            "More details can be found <a target=\"_blank\" href=\"http://www.reunion.gouv.fr/onvousrepond-faq-sur-les-modalites-d-entree-sur-le-a6776.html\">here</a>."
        ]
    },
    {
        "countryCode": "RO",
        "countryName": "Romania",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Romania."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Romania with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Most travelers can enter Romania but there are restrictions. Travelers from South Africa, Botswana, Eswatini, Lesotho, Malawi, Mozambique, Namibia, or Zimbabwe are not allowed to enter Romania. Entry restrictions do not apply to Romanian citizens and residents EU and Schengen citizens, Family members of an EU and Schengen citizen, and essential travel."
        ]
    },
    {
        "countryCode": "RU",
        "countryName": "Russia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Russia with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Russia."
            }
        ],
        "borderDetails": [
            "Entry to the Russian Federation of foreign citizens of other countries, as well as stateless persons, is restricted until further notice. Only Russian citizens and those who have a residence permit are allowed to enter the country.\n\n",
            "The issuance of tourist visas has also been temporarily suspended. Visas of all categories shall be issued only to citizens included in the list of permitted countries published in their <a target=\"_blank\" href=\"https://tourism.gov.ru/contents/turistam/informatsiya-dlya-vezzhayushchikh-v-rossiyu/otdykh-v-rossii-v-2021-godu-iz-kakikh-stran-mozhno-priekhat/\">Tourism website</a>.",
            "Permitted travelers arriving by air are required to register and fill out the <a target=\"_blank\" href=\"https://www.gosuslugi.ru/394604/1\">electronic form</a> prior to arrival in the country."
        ]
    },
    {
        "countryCode": "RW",
        "countryName": "Rwanda",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Rwanda."
            }
        ],
        "borderDetails": [
            "Travelers must complete a <a target=\"_blank\" href=\"https://rbc.gov.rw/travel/\">Public Health Passenger Locator Form.</a>\r\n Travelers will receive a confirmation email with their Unique Health Code (UHC) which they will present upon arriving in Rwanda."
        ]
    },
    {
        "countryCode": "BL",
        "countryName": "Saint Barthélemy",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Saint Barthélemy."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Saint Barthélemy with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Personal or family reason, a health reason relating to an emergency, or a professional reason that cannot be postponed are exempt."
        ]
    },
    {
        "countryCode": "KN",
        "countryName": "Saint Kitts and Nevis",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Saint Kitts and Nevis."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "LC",
        "countryName": "Saint Lucia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Saint Lucia."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Saint Lucia with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "ALL travelers 18 years and older must complete and submit an online travel registration form to receive Travel Authorization\nALL travelers 5 years and over must take a COVID PCR test 5 days or less before arrival in Saint Lucia and submit the negative results to the travel registration form for review and approval. Note – Your test must be based on a nasopharyngeal swab, saliva samples are not accepted.",
            "*Fully vaccinated – two weeks have passed since the second dose of a two-dose vaccine, or since a single-dose vaccine. Vaccine proof must be presented on entry to Saint Lucia.",
            "If you are travelling with children aged 5-17 years and you would like them to also be exempt from quarantine they will need to be retested on arrival at your cost, and they must remain in quarantine until the results of the test are known. Once the test is negative they will not be required to quarantine."
        ]
    },
    {
        "countryCode": "MF",
        "countryName": "Saint Martin",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Saint Martin."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Saint Martin with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Most travelers can enter Saint Martin but there are restrictions."
        ]
    },
    {
        "countryCode": "VC",
        "countryName": "Saint Vincent and the Grenadines",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Saint Vincent and the Grenadines."
            }
        ],
        "borderDetails": [
            "International flights to St. Vincent and the Grenadines are limited."
        ]
    },
    {
        "countryCode": "WS",
        "countryName": "Samoa",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Samoa."
            }
        ],
        "borderDetails": [
            "Travelers will be assessed on a case-by-case basis before granted entry. Travelers are advised to communicate with the Samoa Health Authorities, 21 days before their planned travel at enquiries@health.gov.ws or samoahealth.travelenquiries@gmail.com. These travel restrictions do not apply to Samoan citizens, permanent residents, essential workers and exceptional circumstances approved by Cabinet."
        ]
    },
    {
        "countryCode": "ST",
        "countryName": "São Tomé and Príncipe",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter São Tomé and Príncipe."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "SA",
        "countryName": "Saudi Arabia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Saudi Arabia."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Saudi Arabia with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Travelers must have valid tourism visa obtained before arrival. Visa on arrival remains suspended for health and safety reasons They must also have COVID 19 insurance. This insurance is included in all new tourism visa issues. If you are entering on a previously issued tourism visa, you will need to pay an additional fee of SAR 40 at the airport on arrival to cover insurance for any COVID-19 related medical expenses.",
            "All visitors arriving in the country with a valid tourism visa must provide evidence of a full course of one the four vaccines currently recognized: two doses of the Oxford/Astra Zeneca, Pfizer/BioNTech or Moderna vaccines or a single dose of the vaccine produced by Johnson and Johnson.Guests who have completed two doses of the Sinopharm or Sinovac vaccines will be accepted if they have received an additional dose of one of the four vaccines approved in the Kingdom."
        ]
    },
    {
        "countryCode": "SN",
        "countryName": "Senegal",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Senegal with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Senegal without restrictions."
            }
        ],
        "borderDetails": [
            "Travelers going to Senegal are required to fill out the \r\n<a target=\"_blank\" href=\"https://senegal-embassy.uk/wp-content/uploads/2021/10/Locator_Form_Senegal-EN.pdf\">Passenger Locator Form.</a>"
        ]
    },
    {
        "countryCode": "RS",
        "countryName": "Serbia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Serbia."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Serbia without restrictions."
            }
        ],
        "borderDetails": [
            "Traveling to Serbia is allowed provided that travelers can prove that they are protected from COVID-19 by presenting any of the following : negative RT-PCR test, certificate of complete vaccination, certificate of recovery, or EU digital certificate.\n\n",
            "Minors aged 12 to 18, may enter Serbia without a negative test result, a vaccination certificate, or a certificate of recovery if they submit themselves to testing upon arrival. The test result must be submitted to the territorially competent public health institute of Servia within 48hrs from the time of crossing the state border.",
            "Travelers arriving from countries of special risk (South Africa, Botswana, Zimbabwe, Namibia, Lesotho, Mozambique, Malawi), or that have been in a country of special risk within the last 14 days prior to entering Serbia, must also additionally present:",
            "1)An e-mail received from the <a target=\"_blank\" href=\"http://www.e-zdravlje.gov.rs/\">Foreigners Surveillance Registration</a>, as a proof of successful registration prior to arrival to Serbia",
            "2)Filled-in and personally signed <a target=\"_blank\" href=\"https://www.mfa.gov.rs/sites/default/files/inline-files/1638458815598551.docx\">Statement of Consent</a> of acceptance of quarantine measures at home",
            "To know more of Serbia's entry conditions, please check the <a target=\"_blank\" href=\"https://www.mfa.gov.rs/en/citizens/travel-serbia/covid-19-entry-requirements\">Serbian Ministry of Foreign Affairs</a> website."
        ]
    },
    {
        "countryCode": "SC",
        "countryName": "Seychelles",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Seychelles."
            }
        ],
        "borderDetails": [
            "Online Health Travel Authorization (HTA) is required prior to boarding flights to Seychelles. Visitors\r\nmust submit their negative PCR test results, flight and accommodation details for HTA before\r\ndeparture through the \r\n <a target=\"_blank\" href=\"https://seychelles.govtas.com/ \">Seychelles Travel Authorization Online Portal</a>.\r\n\r\n",
            "Visitors must have valid travel health insurance that covers at minimum COVID-19 testing, isolation,\r\nquarantine and clinical care."
        ]
    },
    {
        "countryCode": "SL",
        "countryName": "Sierra Leone",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Sierra Leone with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Sierra Leone without restrictions."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter Sierra Leone by air. Land and sea borders remain closed."
        ]
    },
    {
        "countryCode": "SG",
        "countryName": "Singapore",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Singapore."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Singapore with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Entry to Singapore is permitted for the following:\n\n<ul>-Singapore Citizens and permanent residents (PR)</ul>\n\n<ul>-Long term pass holders and immediate relatives of Singapore citizens/PRs.</ul>\n\n<ul>-Short term social/business visitors - must have a valid SafeTravel Pass or Air Travel Pass.</ul>\n\nApproved short-term visitors must present an insurance that has a minimum coverage of SGD30,000 for COVID-19 medical treatment.\n\n",
            "All permitted travelers, except Singapore citizens and PRs need to present an entry approval from authorities upon entry to the country.",
            "All permitted travelers are required to submit an <a target=\"_blank\" href=\"https://eservices.ica.gov.sg/sgarrivalcard/\">electronic health declaration</a> within 72 hours prior to arrival to Singapore and download the Trace Together App. \n",
            "Travelers may access the <a target=\"_blank\" href=\"https://safetravel.ica.gov.sg/arriving/overview\">Self Help Tool</a>, which will identify whether they may enter Singapore, and which Safe Travel Lane(s) may apply."
        ]
    },
    {
        "countryCode": "SK",
        "countryName": "Slovakia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": false,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to quarantine to enter Slovakia."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Slovakia without restrictions."
            }
        ],
        "borderDetails": [
            "ENTRY BAN DOES NOT APPLY TO:",
            "\r\nTravelers transiting through Slovakia within 8 hours,Students,Travelers with humanitarian reasons for travel, Slovak citizens, permanent or temporary residents,Some family members of citizens or permanent/temporary residents.",
            "Travelers must have proper documentation accrediting their exemption.",
            " Fully vaccinated travelers must be able to show a certificate proving their vaccination status in Slovak, Czech or English. The vaccination certificate must include a stamp of the national authority or be electronically verifiable."
        ]
    },
    {
        "countryCode": "SI",
        "countryName": "Slovenia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Slovenia with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Slovenia without restrictions."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "SB",
        "countryName": "Solomon Islands",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter the Solomon Islands."
            }
        ],
        "borderDetails": [
            "Prospective travelers must submit request to enter through the Office of the Secretary to the Prime Minister or the Ministry of Foreign Affairs and External Trad by writing to the head of the government’s COVID-19 committee, Permanent Secretary James Remobatu <a target=\"_blank\" href=\"https://www.kayak.com/jremobatu@pmc.gov.sb\">(jremobatu@pmc.gov.sb).</a> Prospective travelers should clearly describe any special or compelling circumstances."
        ]
    },
    {
        "countryCode": "SO",
        "countryName": "Somalia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Somalia."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "ZA",
        "countryName": "South Africa",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter South Africa."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "KR",
        "countryName": "South Korea",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter South Korea."
            }
        ],
        "borderDetails": [
            "Korea has temporarily suspended their visa-free travel agreement due to COVID-19, excluding diplomats, officials, or service passport holders; airline crew, and passengers with an APEC Business Travel Card. Visitors will need a valid visa to enter and are advised to consult their nearest Korean Embassy. \r\n\r\n",
            "Entry restrictions do not apply to Korean nationals and their family members, government officials, overseas Koreans, diplomats, and International Agreement holders. Long-term residents are required to apply for a re-entry permit before entry.",
            "Health and safety requirements are subject to change at short notice. Travelers should ensure they have all the latest information from the <a target=\"_blank\" href=\"https://immigration.go.kr/immigration_eng/index.do\"> Korean Immigration Service</a> website before arranging a trip to South Korea."
        ]
    },
    {
        "countryCode": "SS",
        "countryName": "South Sudan",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter South Sudan."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter South Sudan."
        ]
    },
    {
        "countryCode": "ES",
        "countryName": "Spain",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Spain."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Spain without restrictions."
            }
        ],
        "borderDetails": [
            "Entry to Spain is allowed to residents of EU/EEA and the Schengen Associated States; holders of a long-term visa issued by a Member State or Schengen Associated State; health professionals; transport, marine, and aeronautical personnel; diplomats; students; highly qualified workers; humanitarians; residents from countries approved by EU and fully vaccinated individuals. ",
            "British national tourists and UK residents will only be able to travel to Spain if they have a vaccination certificate.",
            "All travelers going to Spain, including transit passengers are required to fill out the <a target=\"_blank\" href=\"https://www.spth.gob.es/\">Health Control Form</a>. The QR code to be obtained once the form has been submitted must be presented at the airport before departure to Spain and on arrival.",
            "More details in entering Spain can be found on this <a target=\"_blank\" href=\"https://www.mscbs.gob.es/en/profesionales/saludPublica/ccayes/alertasActual/nCov/spth.htm\">site</a>."
        ]
    },
    {
        "countryCode": "LK",
        "countryName": "Sri Lanka",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Sri Lanka."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Sri Lanka with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter Sri Lanka."
        ]
    },
    {
        "countryCode": "SX",
        "countryName": "St. Maarten",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter St. Maarten with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter St. Maarten without restrictions."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter Sint Maarten. \n",
            "Travelers arriving at Princess Juliana International Airport (Sint Maarten) are required to self-monitor for symptoms of COVID-19 for 5 days. Travelers will receive a daily email alert providing a link for submitting the required information online. Travelers will receive an automated daily email alert providing a link for submitting the required information online during the first 5 days of their stay. Travelers remaining for a shorter time on the island will have to submit the information for the time of their stay on the island."
        ]
    },
    {
        "countryCode": "SD",
        "countryName": "Sudan",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Sudan."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "SR",
        "countryName": "Suriname",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Suriname."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Suriname with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Travelers who are not fully vaccinated are not allowed to enter Suriname. Restriction does not apply to citizens and residents of Suriname on repatriation flights. And travelers with essential reasons with the approval of the Ministry of Foreign Affairs."
        ]
    },
    {
        "countryCode": "SE",
        "countryName": "Sweden",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Sweden."
            }
        ],
        "borderDetails": [
            "Diplomats, border workers, healthcare professionals, transport personnel, relief workers, and travelers visiting for urgent family reasons may enter Sweden.",
            "Anyone who has been to South Africa, Lesotho, Botswana, Zimbabwe, Mozambique, Namibia or Eswatini (Swaziland) sometime during the last seven days before entering Sweden is requested to get tested for COVID-19 as soon as possible after entry, preferably the same day, and to take a new test after five days.",
            "You are also requested to stay home for seven days after arrival. The recommendation to get tested and stay home applies both to vaccinated and unvaccinated people, and to people who have had a COVID-19 infection. This also applies to those who have presented a negative test result when departing from these countries."
        ]
    },
    {
        "countryCode": "CH",
        "countryName": "Switzerland",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Switzerland."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Switzerland with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Travelers who are not fully vaccinated are not allowed to enter Switzerland. Entry restrictions do not apply to Swiss citizens, permanent residency holders, holders of other specified permits or visas. EU or EFTA citizens and some of their family members.\n",
            "Travelers from South Africa, Botswana, Eswatini, Lesotho, Mozambique, Namibia or Zimbabwe are not allowed to enter Switzerland. Entry restrictions do not apply to citizens and residents of Switzerland and Liechtenstein."
        ]
    },
    {
        "countryCode": "SY",
        "countryName": "Syria",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": false,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Syria."
            }
        ],
        "borderDetails": [
            "This restriction does not apply to Nationals and residents of Syria, Family members and Business travelers."
        ]
    },
    {
        "countryCode": "TW",
        "countryName": "Taiwan",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Taiwan."
            }
        ],
        "borderDetails": [
            "Entry restrictions do not apply to citizens from Taiwan and their family members and foreign nationals with a valid Alien Resident Certificate. Foreign nationals that need to enter Taiwan for emergencies or humanitarian grounds will be evaluated on a case-by-case basis and must receive permission from the CECC before applying for a special entry permit.\n\n",
            "Travelers must have the Quarantine System for Entry on their mobile phone and must fill out the health declaration form."
        ]
    },
    {
        "countryCode": "TJ",
        "countryName": "Tajikistan",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Tajikistan."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "TZ",
        "countryName": "Tanzania",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Tanzania."
            }
        ],
        "borderDetails": [
            "Most travelers can enter Tanzania, but there are restrictions. Temperature and health screening are required for all travelers on arrival in Tanzania."
        ]
    },
    {
        "countryCode": "TH",
        "countryName": "Thailand",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Thailand."
            }
        ],
        "borderDetails": [
            "***UPDATE as of December 28, 2021 : Thailand Pass will be closed for all new TEST & GO and Sandbox applications, except Phuket Sandbox, Ko Samui, Ko Pha-ngan, and Ko Tao, until further notice. Applicants who have received their Thailand Pass QR code can enter Thailand under the scheme they have registered.***\n\n",
            "Thailand is open to travelers from any country and territory around the world via 3 entry schemes:",
            "<b>1. Exemption from Quarantine (Test and Go)</b> - Only for fully vaccinated travelers from <a target=\"_blank\" href=\"https://thailandpass.org/faq/ \">authorized countries and territories</a>. Proof of confirmed <a target=\"_blank\" href=\"https://www.shathailand.com/#mainFrame\">SHA+ or AQ Hotel</a> reservation is required. Please note that new Test and Go applications are temporarily suspended until further notice. ",
            "<b>2. Living in the \"Blue Zone\" Sandbox</b> - Fully vaccinated travelers should spend the first 7 nights within one of the <a target=\"_blank\" href=\"https://www.tatnews.org/2021/10/thailand-reopening-living-in-the-blue-zone-17-sandbox-destinations/\">17 Blue Zone Sandbox</a> destinations prior to visiting other areas in Thailand. Proof of confirmed <a target=\"_blank\" href=\"https://www.shathailand.com/#mainFrame\">SHA+ Hotel</a> reservation is required. This entry scheme is open to all countries except for some South African countries that are currently classified as high-risk : Botswana, Eswatini, Lesotho, Malawi, Mozambique, Namibia, South Africa, and Zimbabwe. Please note that new Blue Sandbox (except the Phuket Sandbox) applications are temporarily suspended until further notice. ",
            "<b>3. Alternative Quarantine (AQ)</b> - entry option for fully or partially vaccinated, and unvaccinated travelers from any country around the world where quarantine is mandatory. Proof of confirmed <a target=\"_blank\" href=\"https://www.shathailand.com/#mainFrame\">AQ Hotel</a> reservation is required. This entry scheme is also open to all countries except for the high-risk South African countries mentioned above.",
            "Please note that international travelers must have medical insurance with a minimum coverage of 50,000USD, covering treatment and expenses including those related to covid-19 for the total duration of their stay in Thailand.",
            "Register online for a <a target=\"_blank\" href=\"https://tp.consular.go.th/\">Thailand Pass QR Code</a>. Upload all required travel documentation. Wait for the approval within 3 to 5 days.",
            " More information on Thailand's new entry requirements available on the \n<a target=\"_blank\" href=\"https://www.tatnews.org/entry-thailand-frequently-asked-questions/ \">Tourism of Thailand</a> website.\n"
        ]
    },
    {
        "countryCode": "BS",
        "countryName": "The Bahamas",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter The Bahamas."
            }
        ],
        "borderDetails": [
            "Most travelers can enter the Bahamas but there are restrictions.\n",
            "Arriving travelers from high-risk countries South Africa, Botswana, Eswatini, Lesotho, Malawi, Mozambique, Namibia, or Zimbabwe are not allowed to enter the Bahamas. This restriction does not apply to Bahamian citizens and residents and Work permit holders."
        ]
    },
    {
        "countryCode": "TG",
        "countryName": "Togo",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Togo."
            }
        ],
        "borderDetails": [
            "Travelers are recommended to have medical or travel insurance before traveling to Togo if they do not already have international health insurance."
        ]
    },
    {
        "countryCode": "TO",
        "countryName": "Tonga",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Tonga."
            }
        ],
        "borderDetails": [
            "Tongan citizens, Tongan Permanent residents and International travelers approved by the Ministry of Health are exempt from this restriction."
        ]
    },
    {
        "countryCode": "TT",
        "countryName": "Trinidad and Tobago",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Trinidad and Tobago."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Trinidad and Tobago with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "The restriction does not apply to Trinidad and Tobago citizens and residents. Fully vaccinated with no symptoms of COVID-19 will not be required to go into quarantine and will not need to be re-tested ",
            "For more details you may visit <a target=\"_blank\" href=\"https://ttravelpass.gov.tt/travel-requirement\">Trinidad and Tobago Government.</a>"
        ]
    },
    {
        "countryCode": "TN",
        "countryName": "Tunisia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Tunisia."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Tunisia with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Travelers must present a reservation receipt proving their intent to comply with the quarantine requirement on arrival in Tunisia. Vaccinated persons are exempt. Entry restriction does not apply to Tunisian citizens and residents. Vaccinated travelers aged 18 years and older are allowed to enter Tunisia."
        ]
    },
    {
        "countryCode": "TR",
        "countryName": "Turkey",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter Turkey with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Turkey without restrictions."
            }
        ],
        "borderDetails": [
            "All travelers, except transit passengers and children 6 years old and below are required to complete the <a target=\"_blank\" href=\"https://register.health.gov.tr/\">Entry Form</a> 72 hours prior to departure date to Turkey.\r\n",
            "Tourists are advised to have a travel insurance with COVID-19 coverage."
        ]
    },
    {
        "countryCode": "TM",
        "countryName": "Turkmenistan",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Turkmenistan."
            }
        ],
        "borderDetails": [
            "Travelers must complete a <a target=\"_blank\" href=\"https://www.icao.int/safety/aviation-medicine/guidelines/AvInfluenza_guidelines_app.pdf\">Public Health Passenger Locator Form</a>"
        ]
    },
    {
        "countryCode": "TC",
        "countryName": "Turks and Caicos Islands",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter the Turks and Caicos Islands."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter the Turks and Caicos Islands with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Arriving unvaccinated travelers are not allowed to enter Turks and Caicos. Travelers who are medically unable to take a vaccine must upload proof of this to the TCI Assured Portal. These restrictions do not apply to returning Turks and Caicos citizens and residents, travelers under the age of 16, and travelers who are fully vaccinated. \n",
            "Temperature screening is required for all travelers on arrival."
        ]
    },
    {
        "countryCode": "TV",
        "countryName": "Tuvalu",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Tuvalu."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "VI",
        "countryName": "U.S. Virgin Islands",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter the U.S. Virgin Islands."
            }
        ],
        "borderDetails": [
            "The entry restrictions do not apply to US citizens, nationals, immigrants, and lawful permanent residents, travelers who are fully vaccinated, travelers under the age of 18, travelers on diplomatic or official foreign government travel, travelers with documented medical contraindications to receiving a COVID-19 vaccine, crew members of airlines or other aircraft operators, participants in certain COVID-19 vaccine trials, travelers issued a humanitarian or emergency exception, travelers with valid visas excluding B-1 for business or B-2 for tourism visas who are citizens of a foreign country with limited COVID-19 vaccine availability, members of the U.S. Armed Forces or their spouses or children under 18 years of age, sea crew members traveling with to a C-1 and D nonimmigrant visa, and travelers whose entry would be in the national interest, as determined by the Secretary of State, Secretary of Transportation, or Secretary of Homeland Security or their designees. \n",
            "Unvaccinated noncitizens and nonimmigrants qualified to enter the US due to an exemption may be subject to more strict procedures upon arrival.",
            "Travelers arriving from other US territories and US states are allowed to enter the USVI."
        ]
    },
    {
        "countryCode": "UG",
        "countryName": "Uganda",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Uganda."
            }
        ],
        "borderDetails": [
            "All travelers whose body temperature is NOT above 37.5° C (99.5°F), do not have a persistent cough, difficulty in breathing or other flu-like symptoms shall be allowed to enter or depart Uganda, following the testing procedures."
        ]
    },
    {
        "countryCode": "UA",
        "countryName": "Ukraine",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter Ukraine."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter Ukraine without restrictions."
            }
        ],
        "borderDetails": [
            "Travelers who have been from South Africa, Botswana, Zimbabwe, Namibia, Lesotho, Eswatini, Mozambique and Malawi for more than 7 days in the last 14 days are prohibited from entering Ukraine. This restriction does not apply to citizens of Ukraine; foreigners and stateless persons permanently or temporarily residing in Ukraine; spouses or children of citizens of Ukraine; employees of foreign diplomatic missions and consular posts, missions of international organizations accredited in Ukraine, and members of their families; foreigners and stateless persons in agreement with the Ministry of Foreign Affairs.\r\n\r\n",
            "All foreign nationals and temporary residence permit holders in Ukraine, regardless of age, are required to have an insurance policy issued by an insurance company registered in Ukraine. The insurance must cover COVID-19 treatment and it should be valid until the duration of their stay in the country. Ukrainian citizens or a person with a permanent residence permit may not provide an insurance policy.",
            "All foreign citizens, holders of a temporary residence permit and stateless persons need to present one of the following documents when entering the country:",
            "1. Certificate of vaccination with vaccines approved by WHO;",
            "2. Negative PCR test or rapid test taken 72 hours prior to schedule of departure to Ukraine; or ",
            "3. Certificate of recovery from COVID-19.",
            "More details on Entry to Ukraine can be found <a target=\"_blank\" href=\"https://visitukraine.today/\">here</a>."
        ]
    },
    {
        "countryCode": "AE",
        "countryName": "United Arab Emirates",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States can enter the United Arab Emirates with a negative COVID-19 test result."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter the United Arab Emirates without restrictions."
            }
        ],
        "borderDetails": [
            "Travel restrictions vary depending on the specific destination within the United Arab Emirates."
        ]
    },
    {
        "countryCode": "GB",
        "countryName": "United Kingdom",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "<b>Unvaccinated visitors</b> from the United States need to provide a negative COVID-19 test result and quarantine to enter the United Kingdom."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Fully vaccinated visitors</b> from the United States can enter the United Kingdom without restrictions."
            }
        ],
        "borderDetails": [
            "Travelers from <a target=\"_blank\" href=\"https://www.gov.uk/guidance/red-list-of-countries-and-territories\">red countries</a> are only allowed to enter the country if they are British or Irish Nationals, or they have residence rights in the UK. They are required to book and quarantine in a <a target=\"_blank\" href=\"https://quarantinehotelbookings.ctmportal.co.uk/\">managed hotel with 2 COVID-19 tests</a> at their <a target=\"_blank\" href=\"https://www.gov.uk/guidance/booking-and-staying-in-a-quarantine-hotel-when-you-arrive-in-england#what-you-need-to-do-before-you-arrive-in-england\">own expense</a>.\r\n\r\n\r\n",
            "Further details in traveling to United Kingdom can be found <a target=\"_blank\" href=\"https://www.gov.uk/guidance/travel-to-england-from-another-country-during-coronavirus-covid-19#not-vaccinated\">here</a>.",
            "\r\n\r\nTravelers may also review the restrictions in <a target=\"_blank\" href=\"https://www.gov.scot/coronavirus-covid-19/\">Scotland</a>, <a target=\"_blank\" href=\"https://gov.wales/coronavirus\">Wales</a>, and <a target=\"_blank\" href=\"https://www.nidirect.gov.uk/campaigns/coronavirus-covid-19\">Northern Ireland</a> in case they will travel to the said places."
        ]
    },
    {
        "countryCode": "US",
        "countryName": "United States",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter the United States."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter the United States with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Effective December 6th, 2021 - All air passengers, regardless of vaccination status and nationality, will need to present a negative COVID-19 viral test taken within 1 day before departure. Exceptions include children under 2 or passengers who can present a proof of COVID-19 recovery certificate from the last 90 days along with a letter from a licensed healthcare provider or a public health official stating that they are cleared to travel.",
            "Effective November 27, 2021 - a new <a target=\"_blank\" href=\"https://www.whitehouse.gov/briefing-room/presidential-actions/2021/11/26/a-proclamation-on-suspension-of-entry-as-immigrants-and-nonimmigrants-of-certain-additional-persons-who-pose-a-risk-of-transmitting-coronavirus-disease-2019/\">Presidential Proclamation</a>\nsuspends the entry of travelers who were physically present in Botswana, Eswatini, Lesotho, Malawi, Mozambique, Namibia, South Africa, and Zimbabwe during the 14-day period preceding their entry or attempted entry into the United States. U.S. citizens, lawful permanent residents, certain family members, and other individuals who meet specified exceptions, who have been in one of the countries listed above in the past 14 days will be allowed to enter the United States.\n",
            "Effective November 8, 2021 - proof of vaccination will be required for most foreign travelers coming into the United States. For purposes of entry into the country, accepted vaccines include FDA-approved or authorized, and WHO Emergency Use Listing vaccines. ",
            "\nU.S. citizens, U.S. nationals, U.S. lawful permanent residents, U.S. immigrants, and other individuals or minors that fulfill the criteria for an exception under the Proclamation and CDC’s Orders are not required to present a proof of vaccination to enter the United States.",
            "Before you travel, please read the official U.S. travel restrictions from the <a target=\"_blank\" href=\"https://www.cdc.gov/coronavirus/2019-ncov/travelers/international-travel/index.html\">CDC</a>. "
        ]
    },
    {
        "countryCode": "UY",
        "countryName": "Uruguay",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "<b>Unvaccinated</b> visitors from the United States will not be allowed to enter Uruguay."
            },
            {
                "status": Status.OPEN,
                "overview": "<b>Vaccinated visitors</b> from the United States can enter Uruguay with a negative COVID-19 test result."
            }
        ],
        "borderDetails": [
            "Most unvaccinated travelers are not allowed to enter Uruguay. Travelers looking for an exemption from the entry ban must have the proper documentation and a valid reason. \n",
            "Uruguayan citizens, travelers with resident status, travelers who are fully vaccinated, travelers who recovered from COVID-19 in no more than 90 days before their travel. They must have proof of a positive antigen or RT-PCR test result from a test taken at least 20 days before arrival, and travelers who can enter Uruguay as long as they have the proper documentation are travelers with business, economic, judicial, humanitarian, diplomatic, work, or family reasons are exempted from this restriction"
        ]
    },
    {
        "countryCode": "UZ",
        "countryName": "Uzbekistan",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Uzbekistan."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "VU",
        "countryName": "Vanuatu",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Vanuatu."
            }
        ],
        "borderDetails": [
            "These travel restrictions do not apply to Vanuatu citizens, Permanent residents (having physically resided in Vanuatu for at least 3 years). Foreigners with valid residency permits issued before April 2020. Dependents of valid residency visa holders who are currently in Vanuatu (dependents must apply for a residency visa before traveling to Vanuatu). Foreigners with a valid Special Category Visa issued before April 2020 (with approval). And partners must have a valid residency or Special Category Visa.\r\n",
            "Temperature screening is required for all travellers on arrival in Vanuatu."
        ]
    },
    {
        "countryCode": "VE",
        "countryName": "Venezuela",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Venezuela."
            }
        ],
        "borderDetails": [
            "Travelers must register <a target=\"_blank\" \r\n\r\nhref=\"http://pasedesalud.casalab.com.ve/\">online</a> prior to departure. This will generate a QR code which must be presented upon arrival."
        ]
    },
    {
        "countryCode": "VN",
        "countryName": "Vietnam",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.CLOSED,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Vietnam."
            }
        ],
        "borderDetails": [
            "Entry restrictions do not apply to Vietnamese citizens, approved official and diplomatic passport holders and their family members, approved essential categories of workers determined by the Government of Vietnam such as experts, investors and managers, and high-tech workers. ",
            " All travelers entering Vietnam are required to:",
            "- Have a vaccination certificate recognized by a competent authority in Vietnam. The second dose or first dose must have been administered at least 14 days and not more than 12 months before entry. Alternatively, show a COVID-19 recovery certificate issued within 6 months before entry.",
            "- Have a negative test certificate of SARS-CoV-2 taken by RT-PCR/RT-LAMP-method test issued within 72 hours (from the time of sampling) before boarding.",
            "- Show that children under 12 years of age traveling with a parent or guardian have taken an RT-PCR test, showing a negative result, within 72 hours before boarding.",
            "- Have medical/travel insurance that covers COVID-19 treatment with a minimum of $50,000 cover.\nParticipate in a package tour from an approved travel agency."
        ]
    },
    {
        "countryCode": "WF",
        "countryName": "Wallis and Futuna",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Wallis and Futuna."
            }
        ],
        "borderDetails": [
            "All visa applications suspended for Fijians seeking entry. Strict health checks and quarantine measures implemented for inbound travellers."
        ]
    },
    {
        "countryCode": "EH",
        "countryName": "Western Sahara",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Western Sahara."
            }
        ],
        "borderDetails": []
    },
    {
        "countryCode": "YE",
        "countryName": "Yemen",
        "borderStatus": Status.CLOSED,
        "unvaccinatedBorderStatus": Status.UNKNOWN,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.CLOSED,
                "overview": "Most visitors from the United States, regardless of vaccination status, will not be allowed to enter Yemen."
            }
        ],
        "borderDetails": [
            "Yemen has suspended all flights to and from Sanaa International Airport, with only very limited international flights taking place. Exemptions are in place for humanitarian relief and essential goods."
        ]
    },
    {
        "countryCode": "ZM",
        "countryName": "Zambia",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": false,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result to enter Zambia."
            }
        ],
        "borderDetails": [
            "Travelers are allowed to enter Zambia. Temperature screening is required for all travellers on arrival in Zambia."
        ]
    },
    {
        "countryCode": "ZW",
        "countryName": "Zimbabwe",
        "borderStatus": Status.RESTRICTIONS,
        "unvaccinatedBorderStatus": Status.RESTRICTIONS,
        "arrivalTestRequired": true,
        "arrivalQuarantineRequired": true,
        "statusOverviews": [
            {
                "status": Status.RESTRICTIONS,
                "overview": "Most visitors from the United States, regardless of vaccination status, need to provide a negative COVID-19 test result and quarantine to enter Zimbabwe."
            }
        ],
        "borderDetails": [
            "people coming from countries with Alpha and Delta variants must take a COVID-19 test on arrival They must take tests on day 3, day 5, and day 10 of quarantine as well."
        ]
    }
];
