/**
 * Inspired By: https://www.kayak.com/travel-restrictions
 * Built Using: https://www.react-simple-maps.io/
 */

import React, { useState } from "react";
import {ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import {data} from "../../data";
import styles from "./Map.module.scss";
import ZoomControls from "./components/ZoomControls/ZoomControls";
import classNames from "classnames";
import {IFilters} from "../App/App";
import Tooltip from "./components/Tooltip/Tooltip";
import { Country, Status } from "../../data";
import {ensureBoolean} from "../Filters/Filters";

const geoUrl =
    "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-10m.json";

export interface Position {
    coordinates: [number, number];
    zoom: number;
}

const getColor = (country: Country | undefined) => {
    switch (country?.calculatedStatus) {
        case Status.CLOSED:
            return 'var(--closed)';
        case Status.RESTRICTIONS:
            return 'var(--restrictions)';
        case Status.OPEN:
            return 'var(--open)';
        case Status.UNKNOWN:
        default:
            return 'var(--default)';
    }
}

/*
const loadData = (): Promise<Country[]> => fetch('./data.json')
    .then(response => {console.log('response', response.json());return response.json();})
    .catch(error => console.log(error));
 */

const mapData = (data: Country[], filters?: IFilters) => {
    if (!data) {
        return [];
    }
    return data.map(country => {
        const calculatedStatus = filterStatus(calculateStatus(country, filters), filters);
        return {...country, calculatedStatus};
    });
};

const calculateStatus = (country: Country, filters?: IFilters): Status => {
    const result = country;
    switch (result.borderStatus) {
        case Status.OPEN:
            return Status.OPEN;
        case Status.CLOSED:
            return Status.CLOSED
        default:
            switch (result.unvaccinatedBorderStatus) {
                case Status.OPEN:
                    return Status.OPEN
                case Status.CLOSED:
                    if (!filters?.isVaccinated) {
                        return Status.RESTRICTIONS
                    } else {
                        return Status.OPEN
                    }
                default:
                    if (filters?.willingToQuarantine) {
                        return Status.OPEN
                    } else {
                        switch (result.arrivalQuarantineRequired) {
                            case true:
                                return Status.RESTRICTIONS
                            case false:
                                return Status.OPEN
                        }
                    }
            }
    }
    return Status.UNKNOWN;
}

const filterStatus = (status: Status, filters: IFilters | undefined) => {
    if (status === Status.OPEN && ensureBoolean(filters?.hideOpen)) {
        return Status.UNKNOWN;
    }
    if (status === Status.RESTRICTIONS && ensureBoolean(filters?.hideRestrictions)) {
        return Status.UNKNOWN;
    }
    if (status === Status.CLOSED && ensureBoolean(filters?.hideClosed)) {
        return Status.UNKNOWN;
    }
    return status;
};

interface Props {
    className?: string;
    filters: IFilters;
}

const START_COORDINATES: [number, number] = [0, 40];

const Map = (props: Props) => {
    const { className, filters } = props;
    const [tooltipContent, setTooltipContent] = useState<React.ReactNode>();
    const [position, setPosition] = useState<Position>({ coordinates: START_COORDINATES, zoom: 1 });
    const mappedData = mapData(data, filters);
    return (<>
        <ComposableMap
            className={classNames(styles.map, className)}
            id="composable-map"
            data-tip=""
            projection="geoMercator"
            projectionConfig={{ scale: 127, center: START_COORDINATES }}
            height={500}
        >
            <ZoomableGroup
                zoom={position.zoom}
                center={position.coordinates}
                onMoveEnd={setPosition}
            >
                <Geographies geography={geoUrl}>
                    {({ geographies }) =>
                        geographies.map((geo) => {
                            const country = mappedData.find(c => c.countryCode === geo.properties.ISO_A2);
                            if (country) {
                                return (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        fill={getColor(country)}
                                        stroke="var(--background)"
                                        strokeWidth="0.5"
                                        onMouseEnter={() => {
                                            if (country) {
                                                setTooltipContent(
                                                    <>
                                                        <h2>{country.countryName}</h2>
                                                        <ul>
                                                            {country.statusOverviews.map(s =>
                                                                <li key={s.overview}
                                                                    dangerouslySetInnerHTML={{__html: s.overview}}/>
                                                            )}
                                                        </ul>
                                                    </>
                                                );
                                            }
                                        }}
                                        onMouseLeave={() => {
                                            setTooltipContent(undefined);
                                        }}
                                    />
                                );
                            }
                            return null;
                        })
                    }
                </Geographies>
            </ZoomableGroup>
        </ComposableMap>
        <Tooltip>{tooltipContent}</Tooltip>
        <ZoomControls position={position} setPosition={setPosition} />
    </>)
};

export default Map;
