import React, {useRef} from "react";
import classNames from "classnames";
import styles from "./Filters.module.scss";
import {useForm} from "react-hook-form";
import { IFilters } from "../App/App";

interface Props {
    className?: string;
    filters: IFilters;
    setFilters: (f: IFilters) => void;
}

// TODO: fix toggle values coming through as strings
export const ensureBoolean = (val: string | boolean | undefined): boolean => (val === 'true' || val === true);

const Filters = (props: Props) => {
    const { className, filters, setFilters } = props;
    const formRef = useRef<HTMLFormElement>(null);

    const { register, handleSubmit, watch, setValue, getValues } = useForm<IFilters>({
        defaultValues: filters
    });

    const formValues: IFilters = getValues();

    console.log('formValues', formValues);

    watch((data) => {
        setFilters(data);
    });

    const onSubmit = handleSubmit((data: IFilters) => {
        setFilters(data);
    });

    return <form
        ref={formRef}
        className={classNames(styles.filters, className)}
        onSubmit={onSubmit}
    >
        <label>
            <span className={styles.labelText}>Origin:</span>
            <select disabled>
                <option>United States</option>
            </select>
        </label>
        <label>
            <span className={styles.labelText}>COVID Vaccinated:</span>
            <input {...register('isVaccinated')} type="checkbox" />
        </label>
        <label>
            <span className={styles.labelText}>Willing to Quarantine:</span>
            <input {...register('willingToQuarantine')} type="checkbox" />
        </label>
        <div className={styles.toggles}>
            <button
                className={styles.open}
                {...register('hideOpen')}
                onClick={() => setValue('hideOpen', !ensureBoolean(formValues.hideOpen))}
                data-selected={!ensureBoolean(formValues.hideOpen)}
            >
                Open
            </button>
            <button
                className={styles.restricted}
                {...register('hideRestrictions')}
                onClick={() => setValue('hideRestrictions', !ensureBoolean(formValues.hideRestrictions))}
                data-selected={!ensureBoolean(formValues.hideRestrictions)}
            >
                Restrictions
            </button>
            <button
                className={styles.closed}
                {...register('hideClosed')}
                onClick={() => setValue('hideClosed', !ensureBoolean(formValues.hideClosed))}
                data-selected={!ensureBoolean(formValues.hideClosed)}
            >
                Closed
            </button>
        </div>
    </form>;
};

export default Filters;
