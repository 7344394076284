import React from "react";
import Map from "../Map/Map";
import Header from "../Header/Header";
import Content from "../Content/Content";
import styles from "./App.module.scss";
import Filters from "../Filters/Filters";
import useLocalStorage from "../../hooks/useLocalStorage";

export const SelectedFiltersLSKey = 'selectedFilters';

export interface IFilters {
    isVaccinated?: boolean;
    willingToQuarantine?: boolean;
    hideOpen?: boolean;
    hideRestrictions?: boolean;
    hideClosed?: boolean;
}

const App = () => {
    const [filters, setFilters] = useLocalStorage<IFilters>(SelectedFiltersLSKey, {
        isVaccinated: false,
        willingToQuarantine: false,
        hideOpen: false,
        hideRestrictions: false,
        hideClosed: false,
    });
    return (<div className={styles.app}>
        <Content className={styles.filters}>
            <Header title="Travel Restrictions" />
            <Filters filters={filters} setFilters={setFilters}/>
        </Content>
        <Map className={styles.map} filters={filters} />
    </div>);
};

export default App;
